.landing-work-with-us {
	h2 {
		text-align: left;
	}
	& > p {
		margin-bottom: 50px;
	}
	&__boxes {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin: 0 auto;
		gap: 30px;

		@media @960-down {
			display: block;
			grid-template-columns: auto;
			grid-template-rows: repeat(3, 1fr);
		}
	}
	&__box {
		border-radius: 15px;
		border: 1px solid #E5E7EA;
		display: grid;
		grid-template-rows: auto 1fr auto 196px;
		margin-bottom: 30px;

		& > h4 {
			justify-self:	start;
			align-self: 	start;
			padding: 20px 20px 10px 20px;
		}
		& > p {
			justify-self:	start;
			align-self: 	start;
			margin-bottom: 10px;
			padding: 0 20px;
		}
		& > a:not(.button) {
			justify-self:	start;
			align-self: 	start;
			padding: 20px;
		}
		&__button {
			padding: 20px;
			justify-self:	stretch;
			align-self: 	end;
			.button {
				display: block;
				text-align: center;
			}
		}
		&__img {
			justify-self:	stretch;
			align-self: 	stretch;
			background-color: #021431;
			border-radius: 0 0 15px 15px;
			background-size: cover;
			border-top: 1px solid #E5E7EA;

			&.--bg-api {
				background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/API-Image.png');
			}
			&.--bg-ui {
				background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/UI-Image.png');
			}
			&.--bg-bulk {
				background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/Bulk-Image.png');
			}
		}
	}
}
