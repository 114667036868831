.header {
	.flexbox();
	.transition();

	@media print {
		display: none;
	}
	
	background-color: rgba(255, 255, 255, 1);
	width: 		100%;
	z-index: 	100;
	position: 	fixed;
	top: 		0;
	border-bottom:	1px solid #EDEEF1;
	height: @header-height;
	
	&__wrapper {
		.flexbox(@justifyContent: space-between);
		width:	100%;
		padding:	0.5rem 100px 0.5rem 120px;
		&.--small {
			@media @960-down {
				padding: 1rem;
			}
		}
		& > div {
			& > a,
			& > div {
				vertical-align:	middle;
				margin-right:	2rem;
				text-decoration:none;
				display: inline-block;
				&:last-child {
					margin-right:	0;
				}
				@media @960-down {
					margin-right:	0;
				}
				&.link {
					color:			#021431;
					font-size:		1rem;
					font-weight:	700;
					cursor: pointer;
					
					&:hover {
						color:		#00A7FF;
					}
					.--dark & {
						color:		rgba(255,255,255,1);
						&:hover {
							color:	#ffffff;
						}
					}
				}
				.fas:not(.fa-user-circle) {
					color:		#00A7FF;
				}
			}
		}
	}
	
	&__logo {
		max-width: 		9.375rem;
		width: 			100%;
		height: 		auto;
		margin-right:	30px;
		@media @960-down {
			margin-right:	1rem;
		}
	}

	.--only-desktop {
		display:	inline-block;
		@media @960-down {
			display:	none;
		}
	}
	.--only-mobile {
		display:	none;
		@media @960-down {
			display:	inline-block;
		}
	}
	
	&.--dark {
		background-color: @blue-medium;
		border-bottom: none;
	}
}



/* Hover menu for the header. Reusable. */

.hover-menu {
	@transitionType:		ease-out;
	@transitionDuration:	0.2s;
	
	position:	relative;
	.hover-menu-container {
		position:	absolute;
		bottom:		0;
		left:		50%;
		transition: opacity @transitionDuration @transitionType, transform @transitionDuration @transitionType;
		transform: scale(0.5,0);
		opacity:	0;
		z-index:	50000;
		box-shadow:	1px 1px 5px rgba(0,0,0,0.1);
		text-shadow:none;
		& > div {
			position:	relative;
			& > .menu {
				position:	absolute;
				top:		-0;
				left:		-13px;
				z-index:	50000;
				padding-top:15px;
				& > div {
					border-radius:		10px;
					background-color:	#ffffff;
					padding:			10px;
					box-shadow:			0px 0px 15px rgba(0, 0, 0, 0.2);
					& > a, & > div {	
						white-space: 		nowrap;
						color:				#021431;
						font-size:			1rem;
						font-weight:		400;
						padding:			10px;
						text-align:			left;
						border-radius:		7px;
						display:			block;
						margin-top:			10px;
						text-decoration:	none;
						padding-right:		40px;
						&:first-child {
							margin-top:		0;
						}
						background-color:	transparent;
						transition:			0.3s ease-out background-color;
						&:hover {
							background-color:	#BFE9FF;
						}
						
						&.sep {
							height:				0px;
							margin:				4px;
							border-top:			1px solid rgba(0,0,0,0.1);
							padding:			0;
						}
						
						&.no-hover {
							&:hover {
								background-color:	transparent;
							}
						}
						&.selected {
							background-color:	#BFE9FF;
						}
					}
				}
			}
		}
		&.left {
			left:	10px !important;
		}
		
		&.hidden {
			display: none;
		}
	}
	
	&.right {
		.hover-menu-container {
			& > div, & > span {
				& > .menu {
					right:		-7px !important;
					left:		auto !important;
				}
			}
		}
	}
	
	
	.--on-hover {
		display:	none;
	}
	.--on-out {
		display:	inline-block;
	}
	&:hover {
		.hover-menu-container {
			transform:	scale(1,1);
			opacity:	1;
		}
		.--on-hover {
			display:	inline-block;
		}
		.--on-out {
			display:	none;
		}
	}
}
