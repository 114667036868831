
.testimonial {
	@face-width: 6.25rem;
	
	display: grid;
	grid-template-rows: 100px auto;
	grid-template-columns: @face-width 1fr;
	grid-template-areas:
		"face identity"
		"quote quote";
	background-color:	#ffffff;
	border-radius:		10px;
	grid-gap:			50px 80px;
	padding:			50px 80px;
	max-width:			680px;
	height:				423px;
	align-content:		start;
	@media @960-down {
		margin:				0;
		margin-left:		20px;
		grid-gap:			10px 40px;
		padding:			35px 15px;
		&:first-child {
			margin-left:	0;
		}
	}
	
	@media @480-down {
		gap: 1rem;
		grid-template-columns: 1fr;
		grid-template-areas:
			"face"
			"identity"
			"quote";
	}
	
	&__face {
		grid-area: 		face;
		justify-self: 	start;
		align-self: 	center;
		border-radius:	50%;
		width:			@face-width;
		height:			@face-width;
		background-color:		#F2F3F4;
		background-size:		cover;
		background-position:	center center;
		mix-blend-mode: luminosity;
	}
	&__identity {
		grid-area: 		identity;
		justify-self: 	end;
		align-self: 	center;
		text-align:		right;
		
		@media @480-down {
			text-align: left;
			justify-self: start;
		}
		
		p {
			font-size: 1.25rem;
		}
	}
	&__quote {
		grid-area: 		quote;
		justify-self: 	stretch;
		align-self: 	start;
		text-align:		justify;
		@media @960-down {
			text-align:		left;
		}
		p {
			font-size: 1.125rem;
		}
	}
}
