.flexbox(@display: flex; @flexFlow: row wrap; @alignItems: center; @justifyContent: center;) {
	display: @display;
	flex-flow: @flexFlow;
	align-items: @alignItems;
	justify-content: @justifyContent;
}

.transition(@delay: 0s; @duration: .3s; @prop: all; @timing: ease-out;) {
	transition-delay: @delay;
	transition-duration: @duration;
	transition-property: @prop;
	transition-timing-function: @timing;
}

.position-center(@position: absolute) {
	position: @position;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
