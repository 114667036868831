.carousel {
	@slide-border-width: 5px;
	@image-max-width-mobile: ~'min(60vw, 25rem)';
	
	width: 100%;
	margin: auto;
	
	&__wrapper {
		width: 100%;
	}
	
	&__imageWrapper {
		position: relative;
	}
	
	&__image {
		position: absolute;
		top: 0;
		left: 0;
		background-size: cover;
		height: 100%;
		width: 100%;
		border-radius: @border-radius-tertiary;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	&__content {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between);
		
		padding: 2rem;
	}
	
	&__statsWrapper {
		.flexbox(@justifyContent: flex-start; @alignItems: flex-start);
		
		margin-top: 2rem;
	}
	
	&__stats {
		.flexbox(@flexFlow: column; @alignItems: stretch);
		
		max-width: 145px;
		
		&:not(:last-child) {
			margin-right: 4rem;
			
			@media @960-down {
				margin: 0;
			}
		}
		
		&:nth-child(n+2) {
			@media @960-down {
				display: none;
			}
		}
		
		h2 {
			color: @blue;
			
			&:not(:only-child) {
				@media @480-down {
					margin-bottom: .5rem;
				}
			}
		}
		
		h6 {
			text-transform: none;
			line-height: 1.3;
		}
	}
	
	
	/*
		Modifiers 
	*/
	
	// Vertical carousels
	&.--vertical {
		.flexbox(@flexFlow: nowrap; @alignItems: stretch);
		
		@media @960-down {
			flex-flow: column;
		}
		
		.carousel__wrapper {
			width: 50%;
			
			@media @960-down {
				width: 100%;
				padding: 0;	
			}
			
			&:first-child {
				@media @960-down {
					margin-bottom: 2rem;	
				}
			}
		}
		
		.carousel__for,
		.carousel__nav {
			height: 100%;
			
			@media @960-down {
				height: auto;	
			}
		}
		
		// Main image
		.carousel__for {
			.flexbox(@flexFlow: column; @alignItems: stretch;);
			
			padding-right: 4rem;
			
			&:focus {
				outline: 0;
			}
			
			@media @960-down {
				flex-flow: row nowrap;	
				max-width: @image-max-width-mobile;
				margin: auto;
				padding: 0;
			}
			
			@media @640-down {
				max-width: 100%;
			}
			
			/* 
				Slick overrides
			*/
			
			.slick-list{
				height: 100%;
				
				@media @960-down {
					height: auto;	
					padding: 1rem;
				}
			}
			
			.slick-track {
				@media @960-down {
					.flexbox();
				}
			}
			
			.slick-slide {
				.flexbox();
				
				padding: 1rem;
				outline: 0;
				
				@media @960-down {
					.flexbox(@flexFlow: column; @alignItems: stretch);
					
					padding: 0;
					height: auto;
				}
			}
		}
		
		// List items
		.carousel__nav {
			cursor: pointer;
			
			h3,
			p {
				opacity: .5;
			}
			
			h3 {
				.transition();
				
				text-transform: uppercase;
			}
			
			@media @960-down {
				cursor: default;
			}
			
			
			/* 
				Slick overrides
			*/
			
			&.slick-dotted.slick-slider {
				margin-bottom: 1.5rem;
			}
			
			.slick-track {
				.flexbox(@flexFlow: column; @alignItems: stretch);
				
				border-left: 5px solid @border-color;
				
				@media @960-down {
					border: none;
					flex-flow: row nowrap;
				}
			}
			
			.slick-track,
			.slick-list {
				@media only screen and (min-width: 961px) {
					transform: translate3d(0, 0, 0) !important;
					height: 100% !important;
				}
			}
			
			.slick-slide {
				.flexbox(@flexFlow: column; @alignItems: stretch);
				
				border: none;
				padding: 2rem;
				position: relative;
				height: 100%;
				
				@media @960-down {
					align-items: center;
					text-align: center;
					padding: 0;
					height: auto;
				}
				
				&:focus {
					outline: none;
					
					h3 {
						opacity: 1;
					}
					
					p {
						opacity: .75;
					}
					
					&::before {
						background-color: @blue;
						
						@media @960-down {
							background-color: transparent;
						}
					}
				}
				
				&::before {
					.transition();
					
					content: '';
					display: inline-block;
					position: absolute;
					height: 100%;
					width: @slide-border-width;
					left: -@slide-border-width;
					background-color: transparent;
					
					@media @960-down {
						display: none;	
					}
				}
				
				&.slick-current {
					&::before {
						background-color: @blue;
						
						@media @960-down {
							background-color: transparent;	
						}
					}
					
					h3 {
						opacity: 1;
					}
					
					p {
						opacity: .75;
					}
				}
			}
		}
		
		// Use 'dots' Slick setting only on mobile 
		.carousel__dots {
			display: none;
			
			@media @960-down {
				max-width: @image-max-width-mobile;
				margin: auto;
				display: block;
			}
		}
	}
	
	
	// Banner carousel
	&.--banner {
		h3 {
			text-transform: uppercase;
			margin-bottom: 1rem;	
		}
		
		
		/* 
			Slick overrides
		*/
		
		.slick-dotted.slick-slider {
			margin-bottom: 0;
		}
		
		.slick-slide {
			.flexbox(@justifyContent: flex-start; @flexFlow: row nowrap; @alignItems: stretch);
			.transition(@prop: border-color);
			.focus-state(@borderColor: @grey-light-medium);
			
			background-color: @white;
			border-radius: @border-radius-tertiary;
			overflow: hidden;
			height: auto;
	
			> div {
				flex: 1;
				
				@media @960-down {
					flex: unset;
				}
			}
			
			@media @960-down {
				flex-flow: column;
				height: auto;
			}
		}
		
		.slick-dots {
			margin: 5rem auto 0;
			width: 40%;
			
		}
	}
	
	
	// Gallery carousel
	&.--gallery {
		margin-top: 5rem;
		
		@media @480-down {
			margin-top: 2rem;
		}
		
		.carousel__imageWrapper {
			margin: .5rem;
		}
		
		/* 
			Slick overrides
		*/
		.slick-slide {
			.transition(@prop: border-color);
			.focus-state();
			
			border-radius: 30px;
			height: auto;
		}
	}
	   
	// Below carousel to move to next slide, Slick overrides
	.slick-dots {
		.flexbox();
		
		position: static;
		
		> li {
			.transition();
			
			background-color: @border-color;
			width: auto;
			height: @slide-border-width;
			border-radius: @border-radius-primary;
			flex: 1;
			
			&.slick-active {
				background-color: @blue;
			}
			
			button {
				width: 100%;
				height: 100%;
				padding: 0;
				
				// Don't show Slick default dots
				&::before {
					color: transparent;
					opacity: 0;
					width: 0;
					height: 0;
					content: '';
				}
			}
		}
	}
	
	// Prev/next buttons
	.slick-prev,
	.slick-next {
		width: 1.5rem;
		height: 1.5rem;
		
		&::before {
			color: @grey-dark;
			font-family: @font-awesome;
			font-size: 1.5rem;
		}
	}
	
	.slick-prev {
		&::before {
			content: '\f053';
		}
	}
	
	.slick-next {
		&::before {
			content: '\f054';
		}
	}
	
	
	
	/*
		Local mixins
	*/
	.focus-state(@borderColor: @white) {
		border: 3px solid;
		border-color: @borderColor;
		cursor: pointer;
		
		&:focus {
			outline: 0;
			border-color: @blue;
		}
	}
}
