.landing-get-started {
    padding: 60px 80px;
    background-color: #E4F5FF;
    border-radius: 15px;
    width: 100%;
    margin-bottom: 50px;
    background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/bg-cards.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    h2 {
        text-align: left;
    }

    @media @960-down {
        padding: 180px 16px 16px;
        background-position: center top;
    }
}
