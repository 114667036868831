// Glider.js Overrides
.how-it-works-glider-wrapper,
.testimonials-glider-wrapper,
.spotlight-glider-wrapper {
	@controls-width: 4.875rem;
	@controls-center: @controls-width/2;
	
	.glider-prev,
	.glider-next {
		background-color: white;
		width: @controls-width;
		height: @controls-width;
		border-radius: 50%;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		color: @blue-dark;
		opacity: 1;
		top: 42%;
		font-size: 1.125rem;
	}
	
	.glider-prev {
		left: -@controls-center;
	}
	
	.glider-next {
		right: -@controls-center;
	}

	.glider-track {
		gap: 1.25rem;
	}
}




// Default Glider.js CSS
.glider-contain{width:100%;margin:0 auto;position:relative}.glider{margin:0 auto;position:relative;overflow-y:hidden;-webkit-overflow-scrolling:touch;-ms-overflow-style:none;transform:translateZ(0)}.glider-track{transform:translateZ(0);width:100%;margin:0;padding:0;display:flex;z-index:1}.glider.draggable{user-select:none;cursor:-webkit-grab;cursor:grab}.glider.draggable .glider-slide img{user-select:none;pointer-events:none}.glider.drag{cursor:-webkit-grabbing;cursor:grabbing}.glider-slide{user-select:none;justify-content:center;align-content:center;width:100%}.glider-slide img{max-width:100%}.glider::-webkit-scrollbar{opacity:0;height:0}.glider-next,.glider-prev{user-select:none;position:absolute;outline:0;background:0 0;padding:0;z-index:2;font-size:40px;text-decoration:none;left:-23px;border:0;top:30%;cursor:pointer;color:#666;opacity:1;line-height:1;transition:opacity .5s cubic-bezier(.17,.67,.83,.67),color .5s cubic-bezier(.17,.67,.83,.67)}.glider-next:focus,.glider-next:hover,.glider-prev:focus,.glider-prev:hover{color:#a89cc8}.glider-next{right:-23px;left:auto}.glider-next.disabled,.glider-prev.disabled{opacity:.25;color:#666;cursor:default}.glider-slide{min-width:150px}.glider-hide{opacity:0}.glider-dots{user-select:none;display:flex;flex-wrap:wrap;justify-content:center;margin:0 auto;padding:0}.glider-dot{border:0;padding:0;user-select:none;outline:0;display:block;cursor:pointer;color:#ccc;border-radius:999px;background:#ccc;width:12px;height:12px;margin:7px}.glider-dot.active,.glider-dot:focus,.glider-dot:hover{background:#a89cc8}@media(max-width:36em){.glider::-webkit-scrollbar{opacity:1;-webkit-appearance:none;width:7px;height:3px}.glider::-webkit-scrollbar-thumb{opacity:1;border-radius:99px;background-color:rgba(156,156,156,.25);box-shadow:0 0 1px rgba(255,255,255,.25)}}
