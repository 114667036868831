.footer {
	background-color: @blue-dark;
	color: @white;
	
	@media print {
		display: none;
	}
	
	.inner {
		padding: 2rem;
		
		@media @960-down {
			max-width: 100%;
		}
	}
	
	&__list {
		* {
			color: @white;
		}
	}
	
	&__header {
		font-weight: 600;
		margin-bottom: .75rem;
		display: inline-block;
	}
	
	&__item {
		a {
			color: @white;
			
			&:hover {
				color: @white;
			}
		}
	}
	
	&__icon-wrapper {
		.flexbox(@display: inline-flex);
	
		width: 30px;
		height: 30px;
		margin-left: .25rem;
		
		> a {
			.flexbox();
			.transition();
			
			width: 100%;
			height: 100%;
			background-color: @white;
			border: 2px solid @blue-dark;
			border-radius: 50%;
			
			&:hover {
				background-color: @blue-dark;
				border-color: @white;
				text-decoration: none;
				
				.footer__icon {
					color: @white;
				}
			}
		}
	}
	
	&__icon {
		.transition(@prop: color);
		
		font-size: .875rem;
		margin-top: 2px;
		color: @blue-dark;
		
		&.fa-instagram {
			margin-left: 1px;
		}
	}
	
	&__logo-wrapper {
		display: block;
	}
	
	&__logo {
		max-width: 125px;
		width: 100%;
		height: auto;
	}
}
