.landing-testimonials {

    h2 {
        text-align: center;
    }
    &__container {
        overflow-x: auto;
        padding: 20px 0;
    }
    &__boxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: 100%;
        @media @960-down {
            width: auto;
        }
    }
    &__box {
        justify-self: center;
		align-self: center;
        border-radius: 15px;
        border: 2px solid #E5E7EA;
        transform: translate(-0, -0);
        box-shadow: 10px 10px 0 #E5E7EA;
        padding: 20px;
        width: 340px;
        transition: all ease-out 0.3s;
        @media @960-down {
            width: 80vw;
        }

        &:hover {
            transform: translate(-5px, -5px);
            box-shadow: 15px 15px 0 #E5E7EA;
        }

        &__logo {
            margin-bottom: 18px;
            & > img {
                height: 68px;
                width: auto;
            }
            &.--medium {
                & > img {
                    height: 42px;
                    width: auto;
                }
            }
        }
        & > p {
            margin-bottom: 10px;
        }
    }
}
