.button {
	.flexbox(@display: inline-flex);
	.transition(@prop: background-color);
	
	border: none;
	box-shadow: none;
	margin: 0;
	outline: 0;
	cursor: pointer;
	line-height: 1;
	font-weight: 600;
	text-decoration: none;
	font-family: @font-family;
	
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}
	
	&.--primary {
		background-color: @button-primary-background;
		color: @button-primary-color;
		
		&:hover,
		&:focus {
			background-color: @button-primary-background-hover;
		}
		
		&:active {
			background-color: @button-primary-background-active;
		}
		
		&[disabled] {
			
		}
	}
	
	&.--decorative {
		cursor: default;
		background-color: @button-primary-background;
		color: @button-primary-color;
	}
	
	&.--blue-transparent {
		background-color: transparent;
		color: @blue;
		
		&:hover,
		&:focus {
			background-color: @button-primary-background-hover;
			color: @button-primary-color;
		}
		
		&:active {
			background-color: @button-primary-background-active;
			color: @button-primary-color;
		}
	}
	
	&.--transparent {
		background-color: @button-transparent-background;
		color: @button-transparent-color;
		border: 2px solid @button-transparent-border-color;
		
		&:hover,
		&:focus {
			background-color: @button-transparent-background-hover;
			
			.box.--dark & {
				color: @button-transparent-color-hover-partners;
			}
			
			.box.--medium & {
				color: @button-transparent-color-hover-brands;
			}
		}
		
		&:active {
			background-color: @button-transparent-background-active;
			
			.box.--dark & {
				color: @button-transparent-color-hover-partners;
			}
			
			.box.--medium & {
				color: @button-transparent-color-hover-brands;
			}
		}
	}
	
	&.--transparent-reverse {
		background-color: @button-transparent-reverse-background;
		border: 2px solid @button-transparent-reverse-border-color;
		
		.box.--dark & {
			color: @button-transparent-reverse-color-dark;
		}
		
		.box.--medium & {
			color: @button-transparent-reverse-color-medium;
		}
		
		&:hover,
		&:focus {
			background-color: @button-transparent-reverse-background-hover;
			
			.box.--dark &,
			.box.--medium & {
				color: @button-transparent-reverse-color-hover;
			}
		}
		
		&:active {
			background-color: @button-transparent-reverse-background-active;
			
			.box.--dark &,
			.box.--medium & {
				color: @button-transparent-reverse-color-hover;
			}
		}
	}
	
	&.--inverted {
		background-color: @button-inverted-background;
		color: @button-inverted-color;
		border: 2px solid @button-inverted-border-color;
		
		&:hover,
		&:focus {
			background-color: @button-inverted-background-hover;
			color: @button-inverted-color-hover;
		}
		
		&:active {
			background-color: @button-inverted-background-active;
			color: @button-inverted-color-active;
			border-color: @button-inverted-border-color-active;
		}
	}
	
	&.--invertedBrands {
		background-color: @button-inverted-brands-background;
		color: @button-inverted-brands-color;
		border: 2px solid @button-inverted-brands-border-color;
		
		&:hover,
		&:focus {
			background-color: @button-inverted-brands-background-hover;
			color: @button-inverted-brands-color-hover;
			border-color: @button-inverted-brands-border-color-active;
		}
		
		&:active {
			background-color: @button-inverted-brands-background-active;
			color: @button-inverted-brands-color-active;
			border-color: @button-inverted-brands-border-color-active;
		}
	}
	
	&.--login {
		background-color: @button-login-background;
		color: @button-login-color;
		
		&:hover,
		&:focus {
			background-color: @button-login-background-hover;
		}
		
		&:active {
			background-color: @button-login-background-active;
		}
	}
	
	&.--loginBrands {
		background-color: @button-login-brands-background;
		color: @button-login-brands-color;
		
		&:hover,
		&:focus {
			background-color: @button-login-brands-background-hover;
			color: @button-login-brands-color-hover;
		}
		
		&:active {
			background-color: @button-login-brands-background-active;
			color: @button-login-brands-color-active;
		}
	}
	
	&.--small {
		border-radius: @border-radius-secondary;
		padding: .3125rem 1.25rem;
		font-size: .75rem;
	}
	
	&.--medium {
		border-radius: @border-radius-primary;
		padding: .75rem 3.125rem;
		font-size: .875rem;
	}
	
	&.--large {
		border-radius: @border-radius-primary;
		padding: .875rem 2.5rem;
		font-size: 1rem;
		
		@media @480-down {
			width: 100%;
		}
	}
	
	&.--inline {
		width: auto;
		margin-top: 2rem;
		
		+ .button.--inline {
			margin-left: .5rem;
		}
		
		@media @480-down {
			width: 100%;
			
			+ .button.--inline {
				margin-left: 0;
				margin-top: .5rem;
			}
		}
	}
	
	&.--hasInput {
		position: absolute;
		height: 40px;
		right: 0;
		bottom: 0;
		border-radius: @border-radius-secondary;
		
		@media @480-down {
			position: static;
			margin-top: 1rem;
			width: 100%;
			height: auto;	
		}
	}
	
	&.--center {
		margin: 0 auto;
	}
	
	&__icon {
		margin-right: .5rem;
		font-size: inherit;
		color: inherit;
		
		&:only-child {
			margin: 0;
		}
	}
	
	&__image {
		max-width: 1.125rem;
	}
	
	&.--disabled,
	&[disabled],
	&--loading {
		cursor: not-allowed;
		pointer-events: none;
	}
	
	&.--disabled,
	&[disabled] {
		opacity: .5;
	}
}
