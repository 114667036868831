.lottie-animation {
	position: relative;
	width: 100%;
	height: 100%;
	
	&__gift {
		position: absolute;
		width: 126%;
		height: 126%;
		bottom: -26%;
		left: -13%;
		z-index: 1;
	}
	
	&__crown {
		position: absolute;
		z-index: 2;
		max-width: 60px;
		top: 45px;
		left: 39px;
	}
}
