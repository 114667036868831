html,
body {
	font-family: @font-family;
	font-weight: 400;
	font-size: 16px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
	margin: 0;
	color: @blue-dark;
}

h1,
h2,
h3,
h4,
h5,
h6,
lh {
	font-weight: 600;
	line-height: 1.2;
}

a {
	cursor: pointer;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	color: @blue;
	font-weight: 700;
	
	&:hover {
		color: @blue;
		text-decoration: underline;
	}
	
	&.alternate-link {
		color: @blue-dark;
		
		&:hover {
			color: @blue-dark;
		}
	}
	
	&.no-decoration {
		color: transparent;
		background-color: transparent;
		display: block;
		
		&:hover {
			color: transparent;
			background-color: transparent;
			text-decoration: none;
		}
	}
}

span,
small {
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	text-transform: inherit;
}

strong {
	color: inherit;
	font-size: inherit;
	font-weight: 700;
}

h1 {
	font-size: 2.5rem;
	line-height: 1.25;
	
	&:not(:only-child) {
		margin-bottom: 1rem;
	}
}

h2 {
	min-height: 0; // Safari clamp bug fix
	font-size: 2.25rem; // Fallback
	font-size: ~'clamp(1.5rem, 1.0500rem + 2.0000vw, 2.25rem)';
	line-height: 1.28;
	
	&:not(:only-child):not(&[class*='__title']) {
		margin-bottom: 1rem;
	}
}

h3 {
	min-height: 0; // Safari clamp bug fix
	font-size: 1.75rem;
	font-size: ~'clamp(1.25rem, 0.9500rem + 1.3333vw, 1.75rem)';
	line-height: 1.2;
	
	&:not(:only-child) {
		margin-bottom: 1rem;
	}
}

h4 {
	font-size: 1.25rem;
	line-height: 1.2;
}

h5 {
	color: @blue;
	font-size: .875rem;
	text-transform: uppercase;
	margin-bottom: .25rem;
}

h6,
p {
	font-size: 1.125rem;
	line-height: 1.5;
}

h6 {
	text-transform: uppercase;
	
	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

p {
	font-weight: 400;
	
	&:not(:first-of-type) {
		margin-top: 1rem;	
	}
}

p[class*='__paragraph'] {
	font-size: 1.375rem;
}

small {
	display: inline-block;
	font-size: .875rem;
}
