.goal-box {
	.flexbox(@flexFlow: column;);
	.transition();
	
	background-color: @white;
	border-radius: @border-radius-primary;
	padding: 3rem 1rem 2rem;
	width: 100%;
	height: 100%;
	cursor: pointer;
	
	@media @640-down {
		padding: 1rem;
		flex-flow: row nowrap;
		
		> [data-image] {
			flex: 1;
		}
		
		> h4 {
			flex: 2;
		}
	}
	
	[data-image] {
		width: 75%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: transparent;
		margin-bottom: 2rem;
		
		@media @640-down {
			margin: 0 1rem 0 0;
		}
	}
	
	[data-image="plane"] {
		background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-plane.svg');
		width: 55%;
		
		@media @640-down {
			height: 65%;
		}
	}
	
	[data-image="giftcard"] {
		background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-giftcard.svg');
		width: 45%;
		
		@media @640-down {
			height: 80%;
		}
	}
	
	[data-image="money"] {
		background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-money.svg');
		width: 32%;
		
		@media @640-down {
			height: 85%;
		}
	}
	
	[data-image="stars"] {
		background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-stars.svg');
	}
	
	
	
	/* 
		Hover state 
	*/
	
	&:hover {
		transform: scale(1.01);
		box-shadow: @box-shadow-primary;
		
		[data-image="plane"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-plane-hover.svg');
			width: 80%;
			
			@media @640-down {
				height: 60%;
			}
		}
		
		[data-image="giftcard"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-giftcard-hover.svg');
			width: 65%;
			
			@media @640-down {
				height: 100%;
			}
		}
		
		[data-image="money"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-money-hover.svg');
			width: 100%;
			
			@media @640-down {
				height: 100%;
			}
		}
		
		[data-image="stars"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-stars-hover.svg');
		}
	}
}
