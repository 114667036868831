.perks-hero {
	display: grid;
	grid-template-columns:	1fr 40%;
	grid-template-areas: 
	"intro img"
	"items img";
	gap: 6rem 2rem;
	
	@media @960-down {
		grid-template-columns:	1fr;
		grid-template-areas: 
		"intro"
		"items"
		"img";
		gap: 3rem;
	}
	
	&__intro {
		grid-area:		intro;
		justify-self:	stretch;
		align-self:		start;
	}
	&__items {
		grid-area:		items;
		justify-self:	stretch;
		align-self:		end;
		display:				grid;
		grid-template-columns:	repeat(2, 1fr);
		gap: 4vw 2vw;
		
		@media @960-down {
			grid-template-columns:	1fr;
			gap: 2rem;
		}
		
		&__item {
			justify-self:	stretch;
			align-self:		stretch;
			border-left:	4px solid #7FD3FF;
			padding-left:	8px;
			
			& > span {
				font-size:		20px;
				font-weight:	700;
				margin-bottom:	12px;
				display:		block;
			}
		}
	}
	&__img {
		grid-area:		img;
		justify-self:	end;
		align-self:		center;
		@media @960-down {
			justify-self:   center;
			margin-bottom:	30px;
		}
		& > img {
			width:	100%;
		}
	}
}


.perks-brands {
	h4 {
		margin-bottom: 2rem;
		font-size:		36px;
		text-align:		left;
		font-weight:	600;
		@media @960-down {
			font-size:		24px;
			text-align:		center;
			margin-bottom:	20px;
		}
	}
	
	&__logos {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		align-items: center;
		justify-items: center;
		gap: 1rem;
		max-width: 70%;
		margin: 0 auto;
		
		@media @960-down {
			grid-template-columns: repeat(3, 1fr);
			max-width: 100%;
		}
		
		@media @480-down {
			grid-template-columns: 1fr;
		}
	}
	
	&__logo {
		display: block;
		max-width: 9.375rem;
		
		+ p {
			@media @960-down {
				display: none;
			}
			
			@media @480-down {
				display: block;
			}
		}
	}
}


.perks-signup {
	display:				grid;
	grid-template-columns:	50% 50%;
	grid-template-areas: 
	"title img"
	"button img";
	gap: 0 2rem;
	
	@media @960-down {
		grid-template-columns:	auto;
		grid-template-areas: 
		"title"
		"img"
		"button";
	}
	
	&__title {
		grid-area:      title;
		justify-self:   start;
		align-self:     start;
		text-align:		left;
		
		@media @960-down {
			text-align:		center;
			margin-bottom:	3rem;
		}
		
		@media @480-down {
			margin-bottom: 2rem;
		}
		
	}
	&__button {
		grid-area:      button;
		justify-self:   stretch;
		align-self:     stretch;
		text-align:		left;
	}
	&__img {
		grid-area:      img;
		justify-self:   stretch;
		align-self:     stretch;
		position:		relative;
		
		& > img {
			position:	absolute;
			right:		0;
			top:		-2vw;
			width:		100%;
			@media @960-down {
				position:	unset;
			}
		}
	}
}


.perks-case-study {
	display: grid;
	grid-template-columns:	60% 40%;
	grid-template-rows:		auto auto;
	grid-template-areas: 
		"header header"
		"quote points";
	
	@media @960-down {
		grid-template-columns:	auto;
		grid-template-rows:		auto;
		grid-template-areas: 
			"header"
			"quote"
			"points";
	}
	
	
	&__header {
		grid-area:      header;
		justify-self:   stretch;
		align-self:     stretch;
		margin-bottom:	30px;
		
		&__case-study {
			color:			#00A7FF;
			font-size:		16px;
			font-weight:	600;
			text-align:		left;
			margin-bottom:	10px;
		}
		&__title {
			vertical-align:	middle;
			margin-bottom:	30px;
			text-align:	left;
			& > img {
				vertical-align:	middle;
				width:			75px;
				height:			45px;
				@media @960-down {
					width:		47px;
					height:		28px;
				}
			}
		}
		& > p {
			max-width:		72%;
			@media @960-down {
				max-width:	100%;
			}
		}
	}
	
	&__quote {
		grid-area:      quote;
		justify-self:   stretch;
		align-self:     stretch;
		background-color:	#ffffff;
		border-radius:		10px;
		padding:			50px;
		@media @960-down {
			padding:		20px 15px;
			margin-bottom:	10px;
		}
		&__quote {
			font-size:		20px;
			line-height: 	30px;
			margin-bottom:	25px;
		}
	}
	
	&__points {
		grid-area:      points;
		justify-self:   stretch;
		align-self:     center;
		& > ul {
			list-style:			none;
			vertical-align:		middle;
			padding-left:		40px;
			@media @960-down {
				padding-left:	0;
			}
			& > li {
				text-align:				left;
				font-size:				18px;
				font-weight:			400;
				vertical-align:			middle;
				background-image:		url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/perks/check.png');
				background-position:	left center;
				background-repeat:		no-repeat;
				line-height:			28px;
				margin:					20px 0;
				padding-left:			38px;
			}
		}
	}
}
