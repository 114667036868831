.hero {
	&__wrapper {
		.flexbox(@flexFlow: row nowrap);
		
		width: 100%;
		max-width: 1220px;
		padding: 2rem;
		margin: 0 auto;
		
		@media @960-down {
			max-width: 640px;
			flex-flow: column;
			align-items: stretch;
		}
		
		@media @960-down-short {
			padding-top: 0;	
		}
	}
	
	&__textWrapper {
		flex: 0 1 40%;
		
		@media @960-down {
			flex: 1;
			order: 2;
		}
	}
	
	&__imageWrapper {
		flex: 1;
		margin-left: 7rem; // Fallback
		margin-left: clamp(5rem, (6vw + 1rem), 7rem);
		
		@media @960-down {
			flex: 1;
			order: 1;
			margin: 0 0 2rem;	
		}
	}
	
	&__video {
		width: 100%;
		height: auto;
	}
	
	&.--switchImage {
		@media @960-down {
			.hero__textWrapper {
				order: 1;
				margin: 0 0 2rem;
			}
			
			.hero__imageWrapper {
				order: 2;
				margin: 0;
			}
		}
	}
}
