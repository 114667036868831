.grid {
	display: grid;
	align-items: center;
	justify-items: stretch;
	width: 100%;
	gap: 0;
	
	&.--oneCol {
		grid-template-columns: 1fr;
	}
	
	&.--twoCol {
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
		
		@media @960-down {
			grid-template-columns: 1fr;
			grid-template-rows: auto;
			gap: 2rem;
		}
		
		[data-section="cta-boxes"] & {
			grid-auto-rows: 1fr;
			
			@media @640-down {
				gap: 0;
			}
		}
		
		[data-section="brands-hero"] & {
			gap: 4rem;
			
			@media @960-down-short {
				padding-top: 0;	
			}
		}
	}
	
	&.--threeCol {
		grid-template-columns: repeat(3, 1fr);
		
		@media @960-down {
			grid-template-columns: 1fr;
			grid-template-rows: auto;
		}
		
		[data-section="about-values"] &,
		[data-section="about-benefits"] & {
			gap: 4rem;
			align-items: start;
		}
		
		[data-section="about-benefits"] & {
			@media @960-down {
				grid-template-columns: repeat(2, 1fr);
			}	
			
			@media @640-down {
				grid-template-columns: 1fr;
				grid-template-rows: auto;
			}
		}
	}
	
	&.--fourCol {
		grid-template-columns: repeat(4, 1fr);
	}
	
	&.--fiveCol {
		grid-template-columns: repeat(5, 1fr);
	}
	
	&.--gap-4-start {
		gap: 4rem;
		align-items: start;
	}
	
	
	/*
		Custom grids
	*/
	&.--hero {
		@media @960-down {
			.grid__item[text-block] {
				max-width: 640px;
				margin: 0 auto;
				padding: 2rem;	
				z-index: 20;
			}
		}
		
		@media @960-down-short {
			.grid__item[text-block] {
				padding-top: 0;
			}
		}
	}
	
	&.--preFooter {
		grid-template-columns: 1fr 42% 1fr;
		gap: 1.5rem;
		
		@media @1280-down {
			grid-template-columns: 1fr;
			width: 60%;
			margin: 0 auto;
		}
		
		@media @960-down {
			width: 75%;
		}
		
		@media @768-down {
			width: 100%;
		}
	}
	
	&.--footer {
		.transition();
		
		grid-template-columns: 1fr 63% 1fr;
		gap: 1.5rem;
		align-items: start;
		
		@media @960-down {
			gap: 1.5rem;
			grid-template-columns: 1fr;
		}
		
		// Nested grid
		.grid.--threeCol {
			align-items: start;
			justify-items: center;
			
			@media @960-down {
				grid-template-columns: 1fr;
				justify-items: start;
				gap: 1.5rem;
			}
		}
	}
	
	&.--goals {
		grid-template-columns: ~'repeat(4, 1fr)';
		grid-template-rows: 17.375rem;
		gap: 1rem;
		align-items: stretch;
		margin-top: 3rem;
		
		@media @960-down {
			grid-template-columns: ~'repeat(2, 1fr)';
			grid-template-rows: ~'repeat(2, 14rem)';
		}
		
		@media @640-down {
			grid-template-columns: 1fr;
			grid-template-rows: ~'repeat(4, 5rem)';
			margin-top: 2rem;
		}
	}
	
	&.--gov-help {
		&:extend(.grid.--goals);
		
		grid-template-rows: auto;
		
		@media @640-down {
			margin-bottom: 4rem;
		}
		
		@media @480-down {
			margin-bottom: 0;
		}
	}
	
	&.--table {
		align-items: stretch;
		border: 1px solid @table-border-color;
		overflow-x: auto;
		border-radius: @border-radius-primary;
		margin: 2rem 0;
		
		.grid__item {
			padding: 1rem;
			border-right: 1px solid @table-border-color;
			border-bottom: 1px solid @table-border-color;
		}
		
		&.--threeCol {
			.grid__item {
				&:nth-child(6n),
				&:nth-child(6n-1),
				&:nth-child(6n-2) {
					background-color: @grey-light-medium;
				}
				
				&:nth-child(3n) {
					border-right: none;
				}
				
				&:nth-last-child(-n+3) {
					border-bottom: none;
				}
			}
			
			@media @960-down {
				grid-template-columns: repeat(3, 1fr);
			}
		}
		
		&.--fourCol {
			.grid__item {
				&:nth-child(8n),
				&:nth-child(8n-1),
				&:nth-child(8n-2),
				&:nth-child(8n-3) {
					background-color: @grey-light-medium;
				}
				
				&:nth-child(4n) {
					border-right: none;
				}
				
				&:nth-last-child(-n+4) {
					border-bottom: none;
				}
			}
		}
		
	}
	
	&__item {
		position: relative;
		height: auto;
		
		&[image-block] {
			@media @960-down {
				grid-row-start: 2;
			}
		}
	
		&.--justifyEnd {
			justify-self: end;
			
			@media @960-down {
				justify-self: start;	
			}
		}
		
		[data-section="business-goals"] &,
		[data-section="cta-boxes"] & {
			height: 100%;	
		}
		
		.--preFooter & {
			// Hide mobile layout on desktop
			&[data-group="logos-mobile"]{
				display: none;
			}
			
			@media @1280-down {
				// Hide desktop layout on mobile 
				&[data-group="logos1"],
				&[data-group="logos2"] {
					display: none;
				}
				
				&[data-group="logos-mobile"]{
					display: block;
					order: 1;
				}
				
				&[data-group="text"] {
					order: 2;
					margin-top: 2rem;
				}
			}
		}
	}
}
