.accordion {
	background-color: @white;
	border-radius: @border-radius-secondary;
	position: relative;
	padding: 2rem 3rem;
	
	@media @480-down {
		padding: 2rem;
	}

	+ .accordion {
		margin-top: 2rem;
	}

	&__iconWrapper {
		.flexbox();
		
		position: absolute;
		left: unset;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
	}

	&__icon {
		.transition(@duration: .2s; @timing: ease-out;);

		width: 100%;
		height: 100%;
		text-align: center;
		transform: rotate(0);
		font-size: 1.75rem;

		.--openAccordion & {
			transform: rotate(90deg);
		}
	}
	
	&__header {
		.flexbox(@justifyContent: flex-start;);
		
		h3:not(:only-child) {
			margin-right: 1.5rem;
			margin-bottom: 0;
		}
	}
	
	&__content {
		.transition(@prop: opacity);
		
		width: 0;
		height: 0;
		opacity: 0;
		overflow: auto;
		
		p {
			opacity: .5;
			padding: 1rem 0 0;
		}
		
		.--openAccordion & {
			width: 100%;
			height: auto;
			opacity: 1;
		}
	}
}
