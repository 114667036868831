.landing-how-it-works {
    margin-bottom: 50px;
    &__header {
        border-top: 4px solid #00A7FF;
        background-color: #F5F5F7;
        padding: 30px 0;
        p {
            max-width: 60%; // Match the grid under. Right image on the grid has a negative translateY. This prevents the text from going under the image on small desktops
            
            @media @960-down {
                max-width: 100%;
            }
        }
    }

    /* Desktop */
    &__desktop-content {
        display: grid;
        grid-template-columns: 60% 40%;
    }
    &__tabs {
        justify-self: start;
		align-self: center;
    }
    
    &__tab {
        display: grid;
        grid-template-columns: auto 1fr;
        cursor: pointer;
        &:not(last-child) {
            margin-bottom: 2rem;
        }
        &__num {
            background-color: #021431;
            color: #ffffff;
            font-size: 1.25rem;
            font-weight: 600;
            border-radius: 10px;
            width: 40px;
            height: 40px;
            justify-self: center;
			align-self: center;
            .flexbox(@display: flex; @flexFlow: row wrap; @alignItems: center; @justifyContent: center;);
            transition: background-color 0.3s ease-out;
        }
        &__text {
            justify-self: start;
			align-self: center;
            padding-left: 20px;
            transition: color 0.3s ease-out;
            @media @960-down {
                h4 {
                    font-size: 1.1rem;
                    transition: color 0.3s ease-out;
                }
                p {
                    font-size: 1rem;
                }
            }
        }

        &.--selected {
            .landing-how-it-works__tab__num {
                background-color: #00A7FF;
            }
            .landing-how-it-works__tab__text {
                & > h4 {
                    color: #00A7FF;
                }
            }
        }
    }
    &__tab-content {
        justify-self: end;
		align-self: center;

        & > img {
            transform: translateY(-70px);
            max-width: 260px;
        }
    }

    /* Mobile */
    &__mobile-image {
        padding: 40px;
        .flexbox(@display: flex; @flexFlow: row wrap; @alignItems: center; @justifyContent: center;);
        & > img {
            width:      auto;
            max-height: 60vh;
        }
    }
}
