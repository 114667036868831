.back-to-top {
	display: none;
	position: fixed;
	bottom: 50px;
	right: 50px;
	z-index: 30;
	
	&__circle {
		.flexbox(@flexFlow: column;);
		.transition();
		
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: @button-primary-background;
		color: @white;
		font-weight: 700;
		font-size: 0.7rem;
		cursor: pointer;
		
		@media @480-down {
			display: none !important;
		}
		
		> i {
			.transition();
			
			font-size: 1rem;
		}
		
		> span {
			font-weight: 700;
			text-transform: uppercase;
		}
		
		&:hover,
		&:focus {
			background-color: @button-primary-background-hover;
			
			i {
				transform: translateY(-.15rem);
			}
		}
	}
}
