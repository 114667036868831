.terms {
	padding-top: @header-height;
		
	p,
	li {
		text-align: justify;
		text-justify: inter-word;
	}
	
	.grid * {
		text-align: left;
		text-justify: unset;
	}
	
	h2 {
		text-transform: uppercase;
		font-size: 1.125rem;
		line-height: 1.3;
		font-weight: 700;
		margin-bottom: 1rem;
	}
	
	h3 {
		&:extend(.terms h2);
		
		text-transform: none;
		margin: 1rem 0;
	}
	
	ul,
	ol,
	dl {
		padding-left: 1.125rem;
	}
	
	li {
		list-style-type: revert;
		font-size: 1.125rem;
		margin-bottom: 2rem;
		
		&::marker {
			font-size: 1.125rem;
			font-weight: 700;
		}
		
		> ul,
		> ol,
		> dl {
			padding: revert;
			
			> li {
				margin-bottom: 1rem;
			}
		}
		
		p + ul,
		p + ol,
		p + dl {
			margin-top: 1rem;
		}
	}

	li > h2 ~ p {
		margin-left: -1.125rem;
	}
	
	.without-heading {
		li {
			&::marker {
				text-transform: none;
				font-family: inherit;
			}
		}
	}
	
	[data-section="privacy"] {
		h2 {
			margin-top: 2rem;
		}
		
		ul,
		ol,
		dl {
			margin: 1rem 0;
			padding: revert;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		li {
			margin-bottom: .5rem;
		}
	}
	
	[data-section="merchant-terms"] {
		h2 {
			text-transform: none;
			display: inline-block;
			font-size: 1rem;
			
			&.inline {
				margin-bottom: 0;
				
				+ p {
					display: inline;
					margin-left: .5rem;
				}
			}
			
			~ p {
				margin-left: 0;
			}
			
			+ ol {
				padding-left: 0;
				
				li > ol,
				li > ol > li > ol {
					margin-top: 1rem;
				}
			}
		}
		
		li {
			line-height: 1.5;
		}
		
		.without-heading {
			> li {
				list-style-position: inside;
				
				&::marker {
					font-size: 1rem;
					font-weight: 700;
				}
				
				> ol {
					list-style-type: lower-alpha;
					
					> li {
						text-indent: 2rem;
						margin-bottom: 1rem;
						
						&::marker {
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}


// Custom style for those terms to fix the H sizing (H1 is gigantic, H2 looks really small in comparison)
.partner-rewards-terms {

	h1 {
		font-size: 1.2rem;
	}

	h2:not(.inline) {
		display: block;
	}

	p:not(:first-of-type) {
		margin: 0.75rem;
	}

	ul {
		& > li {
			margin-bottom: 0;
		}
	}

	.list-spacer {
		padding:0 1rem;
	}

	.side-by-side {
		.flexbox(@justifyContent: flex-start; @alignItems: flex-start;);
	}

	table.terms-exhibit-table {
		border: 0;
		background-color: #000000;
		border-spacing: 1px;
		border-collapse: separate;
		min-width: 80%;
		margin: 0 auto;
		& > tbody {
			& > tr {
				& > td {
					background-color: #ffffff;
					padding: 5px;
					text-align: left;
					vertical-align: center;
					width: 50%;
				}
			}
		}
	}
}
