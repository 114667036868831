.taglist {
	.flexbox(@flexFlow: row wrap;);
	
	@media @960-down {
		.flexbox(@flexFlow: column;);
	}
	.tag {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 3.75rem auto;
		grid-template-areas:
			"icon label";
		margin:			1.25rem;
		width:			auto;
		height:			4.5rem;
		border-radius:	.75rem;
		cursor:			default;
		
		@media @960-down {
			width:		100%;
			margin:		.75rem;
		}
		
		& > img {
			grid-area: 		icon;
			justify-self: 	end;
			align-self: 	center;
			max-width:		2rem;
			max-height:		2rem;
		}
		
		& > span {
			grid-area: 		label;
			justify-self: 	start;
			align-self: 	center;
			font-size:		1.2rem;
			padding:		0 1.5rem 0 .875rem;
		}
	}
}
