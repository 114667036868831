.landing-hero {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 100px;

	@media @960-down {
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		gap: 30px;
	}

	&__content {
		justify-self:	stretch;
		align-self: 	center;
	}
	&__image {
		justify-self:	center;
		align-self: 	center;

		& > img {
			width: 100%;
			max-width: 370px;
		}
	}
}
