.text-block {
	.flexbox(@flexFlow: column; @alignItems: stretch);
	
	// Text on the right column
	width: 100%;
	max-width: 420px; // Fallback
	max-width: ~'clamp(23rem, (31vw + 1rem), 27rem)';
	height: auto;
	margin-left: 7rem;
	
	@media @960-down {
		max-width: 100%;
		order: 1;
		margin: 0;
	}
	
	// Full width text block
	&.--fullWidth {
		max-width: 100%;
		margin: 0;	
	}
	
	// Text block is on the left
	&.--alternate {
		margin: 0 3rem 0 0;
		max-width: 470px; // Fallback
		max-width: ~'clamp(20rem, (25vw + 1rem), 30rem)';
		
		@media @960-down {
			max-width: 100%;	
		}
	}
	
	h4 {
		margin-bottom: 1rem;
	}
}
