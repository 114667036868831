.gov-help-item {
	.flexbox(@flexFlow: column; @justifyContent: flex-start);
	
	@media @640-down {
		flex-flow: row nowrap;
		background-color: @white;
		border-radius: @border-radius-primary;
		padding: 1rem;
		width: 100%;
		padding: .75rem 1rem;
	}
	
	&__icon {
		max-width: 7.5rem;
		height: auto;
		margin-bottom: 2rem;
		
		@media @640-down {
			max-width: 3.375rem;
			margin: 0 1rem 0 0;
		}
	}
	
	&__text {
		text-align: center;
		line-height: 1.2;
	}
}
