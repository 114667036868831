.image-block {
	@box-padding: .75rem;
	@box-padding-small: .5rem;
	@button-height-large: 2.75rem;
	@button-height-medium: 2rem;
	@button-height-small: 1.6rem;
	@button-height-tiny: 1.2rem;

	display: grid;
	grid-template-columns: 1fr; // Position layers inside one image block
    align-items: center;
	width: 100%;
	height: auto;
	margin: 0 auto;
	
	@media @960-down {
		max-width: ~'calc(480px - 4rem)';
		margin-top: 1rem;
		
		.--hero & {
			margin-top: 0;
		}
	}
	
	> div {
		grid-area: ~'1 / 1';
	}
	
	&__top-layer {
		display: grid; // Nested grid inside child
		grid-template-columns: 1fr; // Position layers inside one image block
		align-items: end; // Put button at the bottom
		height: auto;
		z-index: 20;
		
		> div {
			grid-area: ~'1 / 1';
		}
	}
	
	&__bottom-layer {
		height: auto;
		z-index: 10;
	}
	
	&__button-layer {
		width: 100%;
		max-width: ~'calc(100% - (@{box-padding} * 2))';
		z-index: 30;
		margin: 0 auto;
		margin-bottom: @box-padding;
		
		.button.--medium {
			@media @1280-down {
				padding: .475rem;
			}
			
			@media @480-down {
				padding: .375rem;
			}
		}
		
		.button__image {
			@media @1280-down {
				max-width: 1rem;
			}
			
			@media @480-down {
				max-width: .75rem;
			}
			
			@media @tiny {
				max-width: .5rem;
			}
		}
		
		@media @480-down {
			max-width: ~'calc(100% - (@{box-padding-small} * 2))';
			margin-bottom: @box-padding-small;
		}
	}
	
	// Position bottom image
	&__image-positioner {
		left: 0;
		position: relative;
		max-width: 100%;
		width: 100%;
	}
	
	// Background image
	&__image {
		position: absolute;
		top: 0;
		left: 0;
		background-size: cover;
		height: 100%;
		width: 100%;
		border-radius: @border-radius-tertiary;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	// Main - Hero
	[data-section="0"] & {
		.image-block__bottom-layer,
		.image-block__top-layer {
			max-width: 100%;
			
			.image-block__image {
				background-position: top right;
			}
		}
		
		.image-block__bottom-layer {
			filter: blur(5px);
		}
		
		@media @960-down {
			max-width: 100%;
			margin: 0;	
		}
	} 
	
	// Main - Section 1
	[data-section="1"] & {
		@layer-width-mobile: 65%;
		
		.image-block__bottom-layer,
		.image-block__top-layer {
			@media @480-down {
				max-width: @layer-width-mobile !important;
			}
		}
		
		.image-block__bottom-layer {
			@media @480-down {
				margin-left: ~'calc(100% - 65%)' !important;
			}
		}
	} 
	
	
	
	/*
		Slides within carousel-vertical-why (brands page)
	*/
	
	// Guaranteed-revenue item
	&[data-item="guaranteed-revenue"] {
		@bottom-layer-width: 66%;
		@bottom-layer-image-url: "https://static.prizeout.com/website-redesign/images/about/why-prizeout/GuaranteedRevenue.png";
		
		@top-layer-width: 46%;
	
		.image-block__bottom-layer {
			.bottom-layer-settings(@bottom-layer-width);
		}
		
		.image-block__image {
			.image-layer-settings(@bottom-layer-image-url);
		}
		
		.image-block__top-layer {
			.top-layer-settings(@top-layer-width);
		}
	}
	
	// Sticky users item
	&[data-item="sticky-users"] {
		@bottom-layer-width: 70%;
		@bottom-layer-image-url: "https://static.prizeout.com/website-redesign/images/about/why-prizeout/StickyUsers.png";
		
		@top-layer-width: 50%;
	
		.image-block__bottom-layer {
			.bottom-layer-settings(@bottom-layer-width);
		}
		
		.image-block__image {
			.image-layer-settings(@bottom-layer-image-url);
		}
		
		.image-block__top-layer {
			.top-layer-settings(@top-layer-width);
		}
		
		.box__inner {
			div {
				width: 100%;
				
				> img {
					max-width: 60%;
					margin: 0 auto;
					z-index: 1;
				}
				
				> span {
					.position-center();
					
					z-index: 2;
					font-weight: 700;
					font-size: 2.75rem;
				}
			}
		}
	}
	
	// Cost-effective item
	&[data-item="cost-effective"] {
		.flexbox();
		
		.grid {
			gap: 2rem;
		}
	}
	
	// Set up item
	&[data-item="set-up"] {
		@bottom-layer-width: 60%;
		@bottom-layer-image-url: "https://static.prizeout.com/website-redesign/images/about/why-prizeout/SimpleSetUp.png";
		
		@top-layer-width: 58%;
	
		.image-block__bottom-layer {
			.bottom-layer-settings(@bottom-layer-width);
		}
		
		.image-block__image {
			.image-layer-settings(@bottom-layer-image-url);
		}
		
		.image-block__top-layer {
			.top-layer-settings(@top-layer-width);
		}
	}
	
	[data-section="brands-carousel-vertical-why"] & {
		@media @960-down {
			.image-block__bottom-layer {
				display: none;
			}
			
			.image-block__top-layer {
				max-width: 100%;
			}
		}
	}
	
	
	
	/*
		Local mixins
	*/
	
	.top-layer-settings(@topLayerWidth) {
		max-width: @topLayerWidth;
	}
	
	.bottom-layer-settings(@bottomLayerWidth) {
		max-width: @bottomLayerWidth;
		margin-left: ~'calc(100% - @{bottomLayerWidth})';
	}
	
	.image-layer-settings(@imageUrl) {
		background-image: ~'url("@{imageUrl}")';
	}
}
