.landing-faq {
    padding: 4rem 0;

    h2 {
        color: #40BDFF;
        font-size: 1.25rem;
        @media @960-down {
            text-align: center;
        }
    }
    &__item {
        border-bottom: 2px solid #00A7FF;
        padding: 1rem 0;
    }
    &__question {
        .flexbox(@display: flex; @flexFlow: row nowrap; @alignItems: center; @justifyContent: space-between;);
        cursor: pointer;
        h3, span {
            color: #00A7FF;
            font-size: 2rem;
        }
        span {
            margin-right: 1rem;
        }
    }
    &__answer {
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
        padding-right: 4rem;
        margin-bottom: 1rem;
    }
}
