.image-tag {
	@tag-background-color: rgba(0, 167, 255, .8);
	@tag-border-radius: 67px;
	@image-border-radius: 30px;
	
	position: relative;
	
	&:not(:last-child) {
		@media @960-down {
			display: none;
		}
	}
	
	&__image {
		border-radius: @image-border-radius;
	}
	
	&__tag {
		.flexbox(@flexFlow: nowrap);
		
		background-color: @tag-background-color;
		border-radius: @tag-border-radius;
		position: absolute;
		bottom: 1rem;
		right: -1rem;
		padding: .25rem 1rem;
	}
	
	&__icon,
	&__text {
		color: @white;
		font-size: 1rem;
	}
	
	&__text {
		margin-left: .5rem;
	}
}
