.image-text-stack {
	.flexbox(@flexFlow: column; @justifyContent: flex-start;);
	
	&__image {
		max-height: 12.5rem;
		margin: 0 auto;
	}
	
	&__circ {
		.flexbox();
		
		width: 2rem;
		height: 2rem;
		background-color: @white;
		border: 2px solid @blue;
		margin: 3rem auto 1rem;
		border-radius: 50%;
	}
	
	&__number {
		font-size: 1rem;
		font-weight: 700;
		color: @blue;
	}
	
	&__message {
		text-align: center;
	}
	
	&__heading {
		margin-bottom: .5rem;
	}
}
