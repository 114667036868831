.callout {
	.flexbox(@justifyContent: flex-start; @alignItems: flex-start; @flexFlow: row nowrap);
	
	&__column {
		flex: 1;
		
		&:not(:first-child) {
			margin-left: 1rem;
		}
	}
	
	&__large {
		font-size: 2.25rem;
		line-height: 1.28;
		margin-bottom: .25rem;
		font-weight: 700;
	}
	
	&__text {
		margin: 0;
		line-height: 1.3;
		
		&:not(:first-of-type) {
			margin-top: 0;	
		}
	}
}
