.icon-text {
	.flexbox(@alignItems: stretch; @justifyContent: flex-start; @flexFlow: nowrap);
	
	width: 100%;
	
	&:not(:last-child) {
		margin-bottom: 1rem;	
	}
	
	&.--column {
		.flexbox(@flexFlow: column; @alignItems: stretch);
		
		margin: 0;
		
		@media @640-down {
			align-items: center;
		}
	}
	
	&__icon {
		font-size: 1.5rem;
		
		&.--medium {
			font-size: 2.5rem;
		}
		
		&.--blue {
			color: @blue;
		}
		
		&.--mediumBlue {
			color: @blue-medium;
		}
		
		.--column & {
			margin-bottom: 1rem;
		}
	}
	
	&__svg {
		width: 2rem;
		
		.--column & {
			width: auto;
			height: 6rem;
			margin-bottom: 2rem;
		}
	}
	
	&__text {
		font-size: 1.125rem;
		margin-left: .75rem;
		
		&.--block {
			.flexbox(@flexFlow: column; @alignItems: stretch;);
			
			margin-left: 1.25rem;
			
			> p {
				margin: 0;
				font-size: inherit;
				font-weight: 600;
				font-family: inherit;
			}
		}
		
		.--column & {
			margin-left: 0;
			
			@media @640-down {
				text-align: center;
			}
		}
		
		h3 {
			margin-bottom: 1rem;
		}
	}
}
