.gov-hero {
	.flexbox(@justifyContent: flex-start;);
	
	background-image: url('https://assets.prizeout.com/gov/gov-hero.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	height: 657px;
	margin-top: @header-height;
	
	@media @960-down {
		margin-top: 0;
	}
	
	&__inner {
		width: 100%;
		max-width: 1220px;
		margin: 0 auto;
		padding: 2rem;
		
		@media @960-down {
			max-width: 640px;
		}
	}
	
	&__message {
		background-color: @white;
		border-radius: @border-radius-tertiary;
		z-index: 2;
		width: 45%;
		padding: 2.5rem;
		
		@media @960-down {
			width: 100%;
		}
		
		button {
			margin-top: 1.5rem;
		}
	}
}
