@keyframes bounce {
	0%,
	25%,
	75%,
	100% {
		transform: translateY(0);
	}
	
	50% {
		transform: translateY(-5px);
	}
}
