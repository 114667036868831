/* For screen readers */
.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

ul,
ol {
	margin: 0;
	padding: 0;

	&.--type-alpha {
		& > li {
			list-style-type: lower-alpha !important;
		}
	}
	&.--type-alpha-upper {
		& > li {
			list-style-type: upper-alpha !important;
		}
	}
	&.--type-num {
		& > li {
			list-style-type: decimal !important;
		}
	}
	&.--type-roman {
		& > li {
			list-style-type: upper-roman !important;
		}
	}
	&.--type-none {
		& > li {
			list-style-type: none !important;
		}
	}
	/*
	&.--counter {
		ol {
			counter-reset: item;
		}
		li:before {
			content: counters(item, ".") " ";
			counter-increment: item;
		}
	}*/
}

/*
ol.--with-counter {
	counter-reset: item;
	li {
		display: block;
		&:before {
			content: counters(item, ".") " ";
			counter-increment: item;
		}
	}
}*/

li {
	list-style-type: none;
	font-size: .875rem;
	line-height: 1.3;
	margin-bottom: .5rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.normal-list {
	padding-left: 1.125rem;
	
	li {
		list-style-type: inherit;
		font-size: 1.125rem;
		line-height: 1.5;
		margin-bottom: 1rem;
	}
}

.button-wrapper {
	margin-top: 2rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
	gap: .5rem;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-center-left {
	text-align: center;
	
	@media @640-down {
		text-align: left;
	}
	
	&.--split {
		@media @960-down {
			text-align: left;
			align-items: flex-start;
		}
	}
}

/* Section spacing */
.mb-section-s {
	margin-bottom: 60px;
	@media @960-down {
		margin-bottom: 30px;
	}
}
.mb-section-m {
	margin-bottom: 120px;
	@media @960-down {
		margin-bottom: 60px;
	}
}
.mb-section-l {
	margin-bottom: 160px;
	@media @960-down {
		margin-bottom: 60px;
	}
}

.mt-m {
	margin-top: 30px;
}
.mb-s {
	margin-bottom: 10px;
}
.mb-m {
	margin-bottom: 20px;
}
.mb-l {
	margin-bottom: 50px;
}
.ml-m {
	margin-left: 2rem;
}
.pl-s {
	padding-left: 1rem;
}

.text-indent {
	text-indent: 4rem;
}

.spacer-top {
	margin-top: 2rem;
}
.spacer-top-xs {
	margin-top: .5rem;
}

.spacer-bottom {
	margin-bottom: 2rem;
}

.spacer-bottom-s {
	margin-bottom: 1rem;
}

.spacer-bottom-xs {
	margin-bottom: .5rem;
}

.page-break-before {
	margin-top: 250px;
	@media print {
		margin-top: 0;
		page-break-before: always;
	}
}


.standard-width {
	width: 100%;
	max-width: 1220px;
	padding: 0 2rem;
	&.--large {
		max-width: 1980px;
	}
	&.--medium {
		max-width: 1000px;
	}
	@media @960-down {
		padding: 0 1rem;
	}
}

.divider {
	background-color: @grey-medium;
	width: 100%;
	height: 1px;
	margin: 0;
	border: 0; 
}

.full-height {
	min-height: 100vh;
}

.full-width {
	width: 100%;
}

.inline {
	display: inline;
}


.policy-table {
	border-collapse: collapse;
	border: 1px solid #bfbfbf;
	margin: 2rem 0;
	th {
		border: 1px solid #bfbfbf;
	}
	td {
		border: 1px solid #bfbfbf;
		padding: 5px;
		vertical-align: top;
		p {
			margin-top: 0;
		}
	}
	thead {
		background-color: #bdd6ee;
	}
}
.policy-contact-info {
	padding-left: 1.875rem;
	overflow: auto;
	p {
		margin-top: 0;
		&:first-child {
			margin-top: 0.5rem;
		}
	}
}

.no-login {
	.header__login,
	.hamburger__menu .bottom {
		display: none;
	}
}
