.thank-you {
	img {
		max-width: 5rem;
		margin-bottom: 1.5rem;
	}
	
	h1 {
		font-size: 2rem;
	}
	
	.button {
		margin-top: 1.5rem;
	}
}
