.hamburger {
	@hamburger-width: 24px;
	@hamburger-height: 20px;
	@hamburger-vertical-spacing: 8px;
	@hamburger-vertical-spacing-double: @hamburger-vertical-spacing * 2;
	
	.transition();
	
	display: none;
	overflow: visible;
	margin: 0;
	padding: 0;
	cursor: pointer;
	color: inherit;
	border: 0;
	background-color: transparent;
	
	@media @960-down {
		display: inline-block;
	}
	
	// Animate from hamburger to 'X'
	&.--isActive {
		.hamburger__inner {
			transform: ~'translate3d(0, @{hamburger-vertical-spacing}, 0) rotate(45deg)';
			
			&::before,
			&::after {
				background-color: @blue-dark;
				
				.--dark & {
					background-color: @white;
				}
			}
			
			&::before {
				transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
				opacity: 0;
			}
			
			&::after {
				transform: ~'translate3d(0, -@{hamburger-vertical-spacing-double}, 0) rotate(-90deg)';
				width: 100%;
			}
		}
	}
	
	&__wrapper {
		display: inline-block;
		position: relative;
		width: @hamburger-width;
		height: @hamburger-height;
	}
	
	&__inner,
	&__inner::before,
	&__inner::after {
		.transition();
		
		position: absolute;
		width: @hamburger-width;
		height: 2px;
		border-radius: 3px;
		background-color: @blue-dark;
		
		.--dark & {
			background-color: @white;
		}
	}
	
	&__inner {
		top: 2px;
		display: block;
		margin-top: -2px;
		
		&::before,
		&::after {
			display: block;
			content: '';
		}
		
		&::before {
			.transition();
			
			top: @hamburger-vertical-spacing;
		}
		
		// Bottom bar of the hamburger
		&::after {
			.transition();
			
			top: @hamburger-vertical-spacing-double;
			width: 50%;
			right: 0;
		}
	}
	
	&__menu {
		.transition();
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 90;
		transform: translateX(100%);
		background-color: rgba(255,255,255,1);
		
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		grid-template-areas:
			"top"
			"bottom";
		
		& > .top {
			padding:		20px;
			padding-top:	100px;
			grid-area: 		top;
			justify-self:	start;
			align-self: 	start;
			.hamburger__trigger {
				.fa-angle-up {
					display:	none;
				}
				.fa-angle-down {
					display:	inline-block;
				}
				&.--isActive {
					.fa-angle-up {
						display:	inline-block;
					}
					.fa-angle-down {
						display:	none;
					}
				}
			}
			
			a {
				color:			#021431;
				text-decoration: none !important;
			}
			& > ul {
				list-style-type: none;
				& > li {
					padding:		10px;
					padding-left:	20px;
					a {
						font-size:		1rem;
						font-weight:	700;
						.fas {
							color:		#00A7FF;
						}
					}
					& > ul {
						list-style-type:	none;
						margin-top:			10px;
						& > li {
							padding:		10px;
							padding-left:	20px;
							a {
								font-size:		1rem;
								font-weight:	500;
							}
						}
					}
				}
			}
		}
		& > .bottom {
			grid-area: 		bottom;
			justify-self: 	stretch;
			align-self: 	end;
			padding:		10px;
			& > div {
				padding:	10px;
			}
			a {
				display: 	block;
				text-align:	center;
				width:		100%;
			}
		}
		&.--dark {
			background-color:	@blue-medium;
			& > .top {
				a {
					color:			#ffffff;
				}
			}
		}
	}
	
	&__item {
		width: 100%;
		max-width: 640px;
		text-align: center;
		padding: 0 1rem;
		
		@media @640-down {
			width: 80%;
		}
		
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
		
		a {
			display: block;
		}
	}
}

// Appended to body when toggling the hamburger menu
.hamburger-open {
	@media @960-down {
		position: fixed;
	
		.hamburger__menu {
			transform: translateX(0);
		}
	}
}
