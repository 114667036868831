.logos {
	position: relative;
	width: 100%;
	transform: translate3d(0,0,0);
	
	// Home page, pre-footer
	.home & {
		min-height: 400px;
		
		@media @1280-down {
			min-height: initial;
		}
	}
	
	// Brands page
	.brands & {
		min-height: 310px;
		
		@media @960-down {
			min-height: initial;
			grid-row-start: 1;
		}
	}
	
	&__grid {
		display: grid;
		justify-items: center;
		
		.home & {
			@media @1280-down {
				grid-template-columns: repeat(11, 1fr);
			}
		}
		
		.home &,
		.brands & {
			@media @960-down {
				grid-template-columns: repeat(7, 1fr);
			}
		}
	}
	
	&__logo {
		.transition(@prop: max-width);
		
		position: absolute;
		max-width: @logos-width-large;
		
		&.--bbb {
			max-width: 135px;
		}
		
		&.--dominos {
			max-width: 63px;
		}
		
		&.--doordash {
			max-width: 70px;
		}
		
		&.--hotels {
			max-width: 137px;
		}
	
		&.--nordstrom {
			max-width: 89px;
		}
	
		&.--lowes {
			max-width: 75px;
		}
		
		&.--uber {
			max-width: 141px;
		}
	
		&.--bww {
			max-width: 89px;
		}
		
		// Position logos on home page
		.home & {
			&.--amazon {
				bottom: 0;
				left: 5%;
			}
			
			&.--bbb {
				bottom: 0;
				right: 0;
			}
			
			&.--dominos {
				top: 36%;
				right: 2%;
			}
			
			&.--doordash {
				top: 24%;
				left: 4%;
			}
			
			&.--hotels {
				bottom: 7%;
				right: 0;
			}
			
			&.--nordstrom {
				bottom: 4%;
				left: -7%;
			}
			
			&.--lowes {
				top: 35%;
				left: 37%;
			}
			
			&.--dunkin {
				top: 0;
				right: 14%;
			}
			
			&.--ulta {
				top: 14%;
				left: 0;
			}
			
			&.--uber {
				top: 0;
				left: 40%;
			}
			
			&.--bww {
				bottom: 32%;
				left: 28%;
			}
			
			// 2 rows layout only on home page pre-footer
			@media @1280-down {
				position: static;
				
				// 1st row, offset logos
				&.--ulta {
					grid-column-start: 2;
					grid-row-start: 1;
				}
				
				&.--dunkin {
					grid-column-start: 4;
					grid-row-start: 1;
				}
				
				&.--lowes {
					grid-column-start: 6;
					grid-row-start: 1;
				}
				
				&.--hotels {
					grid-column-start: 8;
					grid-row-start: 1;
				}
							
				&.--amazon {
					grid-column-start: 10;
					grid-row-start: 1;
				}
				
				// 2nd row
				&.--uber {
					grid-column-start: 1;
					grid-row-start: 2;
				}
				
				&.--dominos {
					grid-column-start: 3;
					grid-row-start: 2;
				}
				
				&.--bww {
					grid-column-start: 5;
					grid-row-start: 2;
				}
				
				&.--bbb {
					grid-column-start: 7;
					grid-row-start: 2;
				}
				
				&.--nordstrom {
					grid-column-start: 9;
					grid-row-start: 2;
				}
				
				&.--doordash {
					grid-column-start: 11;
					grid-row-start: 2;
				}
			}
		}
		
		// Position logos on brands page
		.brands & {
			&.--ulta {
				top: 6%;
				left: 18%;
			}
			
			&.--dunkin {
				bottom: -45%;
				right: 0;
			}
			
			&.--lowes {
				top: 62%;
				left: 41%;
			}
			
			&.--hotels {
				bottom: -50%;
				left: -10%;
			}
			
			&.--amazon {
				bottom: -36%;
				left: 32%;
			}
			
			&.--uber {
				top: 23%;
				right: 3%;
			}
			
			&.--dominos {
				top: -50%;
				right: 0;
			}
			
			&.--bww {
				top: -30%;
				left: -8%;
			}
			
			&.--bbb {
				top: -31%;
				right: 26%;
			}
			
			&.--nordstrom {
				top: 55%;
				left: 2%;
			}
			
			&.--doordash {
				bottom: -10%;
				right: 17%;
			}
		}
		
		// 3 column layout for both home and brands pages - 960-down
		.home &,
		.brands & {
			@media @960-down {
				position: static;
				
				// 1st row
				&.--ulta {
					grid-column-start: 1;
					grid-row-start: 1;
				}
				
				&.--dunkin {
					grid-column-start: 3;
					grid-row-start: 1;
				}
				
				&.--lowes {
					grid-column-start: 5;
					grid-row-start: 1;
				}
				
				&.--hotels {
					grid-column-start: 7;
					grid-row-start: 1;
				}
				
				// 2nd row, offset logos			
				&.--amazon {
					grid-column-start: 2;
					grid-row-start: 2;
				}
				
				&.--uber {
					grid-column-start: 4;
					grid-row-start: 2;
				}
				
				&.--dominos {
					grid-column-start: 6;
					grid-row-start: 2;
				}
				
				// 3rd row
				&.--bww {
					grid-column-start: 1;
					grid-row-start: 3;
				}
				
				&.--bbb {
					grid-column-start: 3;
					grid-row-start: 3;
				}
				
				&.--nordstrom {
					grid-column-start: 5;
					grid-row-start: 3;
				}
				
				&.--doordash {
					grid-column-start: 7;
					grid-row-start: 3;
				}
			}
		}
		
		&[class*="--"] {
			.home & {
				@media @1280-down {
					max-width: @logos-width-medium;
				}
			}
			
			.brands & {
				@media @960-down {
					max-width: @logos-width-medium;
				}
			}
			
			.home &,
			.brands & {
				@media @640-down {
					max-width: @logos-width-small;
				}
			}
		}
	}
}
