.landing-brands {
    &__line {
        overflow-x: hidden;
        position: relative;
        &__brands {
            position: relative;
            width:  100%;
            height: 70px;
        }
        &__fade {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255, 0) 15%, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 100%);
        }
    }
    &__brand {
        transition: left linear 0.5s, right linear 0.5s;
        position: absolute;
        width:  60px;
        height:  60px;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
    }
}
