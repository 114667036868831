.spotlight {
	align-content: start;
	
	background-color: @white;
	border: 2px solid @border-color;
	border-radius: @border-radius-primary;
	display: grid;
	grid-template-columns: 42% auto;
	gap: 2.5rem;
	grid-template-areas: 
	"image text";
	
	@media @1024-down {
		grid-template-columns: 46% auto;
		gap: 1.5rem;
	}
	
	@media @960-down {
		
		grid-template-columns: auto;
		grid-template-areas: 
		"image"
		"text";
		border: none;
	}
	
	&__image {
		grid-area: image;
		
		img {
			border-radius: 10px;
		}
	}
	
	&__text {
		grid-area: text;
		align-self: center;
		padding: 1rem;
		
		@media @960-down {
			padding: 0;
			
			.glider-track & {
				padding: 0 10px 10px;	
			}
		}
		
		&.--space-between {
			.flexbox(@justifyContent: space-between; @flexFlow: column; @alignItems: flex-start);
			
			padding: 2rem 2rem 2rem 0;
			align-self: stretch;
			
			@media @960-down {
				padding: 0 1.5rem 1.5rem;
			}
		}
		
		&.--centered {
			.flexbox(@flexFlow: column;);
			
			padding: 2rem 2rem 2rem 0;
			
			@media @960-down {
				padding: 0 1.5rem 1.5rem;
			}
		}
	}
	
	&__logos {
		max-width: 14rem;
		margin-top: 1rem;
	}
}
