.about-hero {
	&__wrapper {
		.flexbox(@flexFlow: row nowrap);
		
		width: 100%;
		max-width: 1220px;
		padding: 2rem 0 2rem 2rem;
		margin: 0 auto;
		
		@media @960-down {
			max-width: 640px;
			flex-flow: column;
			align-items: stretch;
		}
		
		@media @960-down-short {
			padding-top: 0;	
		}
	}
	
	&__textWrapper {
		flex: 0 1 30%;
		
		@media @1280-down {
			flex: 0 1 33%;
		}
		
		@media @1024-down {
			flex: 0 1 35%;
		}
		
		@media @960-down {
			flex: 1;
			order: 2;	
			padding-right: 2rem;
		}
	}
	
	&__imageWrapper {
		flex: 1;
		margin-left: 7rem; // Fallback
		margin-left: clamp(5rem, (6vw + 1rem), 7rem);
		
		@media @960-down {
			flex: 1;
			order: 1;
			margin: 0 0 2rem;	
		}
	}
}
