// Use only for local dev to suppress onetrust cookies modal
// #onetrust-consent-sdk {
// 	display: none !important;
// }

html {
	overflow-x: hidden;
	height: -webkit-fill-available;
}

body {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	
	* {
		box-sizing: border-box;
	}
	
	&.--withModal {
		position: fixed;
	}
}

img {
	width: 100%;
	height: auto;
	display: inline-block;
}

picture {
	display: block;
	
	[data-section="about-hiring"] & {
		max-width: 1000px;
		margin: auto;
	}
}

.main {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.inner {
	width: 100%;
	max-width: 1220px;
	padding: 4rem 2rem;
	margin: 0 auto;
	
	@media @960-down {
		max-width: 640px;
		padding: 2rem;	
	}
	
	&.--small {
		padding: 1rem 2rem;
	}
	
	&.--center {
		.flexbox(@flexFlow: column;);
		
		text-align: center;
		max-width: clamp(640px, (40vw + 1rem), 41%);
	}
	
	&.--textBlock {
		.flexbox(@flexFlow: column; @alignItems: stretch);
		
		max-width: 810px;
		padding: 5rem 0;
		
		@media @960-down {
			max-width: 640px;
			padding: 2rem;
		}
	}
	
	[data-section="cta-boxes"] & {
		@media @640-down {
			padding: 0;
		}
	}
}

.fixed-down-arrow {
	.flexbox();

	width: 100%;
	position: fixed;
	z-index: 90;
	bottom: 0;
	
	&__icon {
		font-size: 2rem;
		color: @blue;
		cursor: pointer;
		height: 3rem;
		animation: 2s ease-in-out bounce infinite;
		
		&:hover,
		&:focus {
			color: @button-primary-background-hover;
		}
	}
}

.overlay {
	position:fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 1000;
}

[class*="-modal"] {
	display: none;
}
