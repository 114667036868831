.pre-footer {
	background-color: @grey-light-medium;
	width: 100%;
	padding: 4rem 2rem;
	text-align: center;
	
	@media @480-down {
		padding: 2rem;
	}
	
	.form {
		.transition();
		
		text-align: left;
		width: 85%;
		margin: 2rem auto 0;
		
		@media @1280-down {
			width: 90%;
		}
		
		@media @640-down {
			width: 100%;
		}
		
		@media @480-down {
			margin-top: 1rem;
		}
	}
	
	#optin-form-confirm {
		width: 80%;
		margin: 2rem auto;
		padding: 2rem;
		text-align: center;
		background-color: @blue;
		border-radius: @border-radius-primary;
		
		@media @480-down {
			width: 100%;
			padding: 1.5rem;
		}
		
		> * {
			color: @white;
		}
		
		h4 {
			margin-bottom: 1rem;
		}
	}
}
