/* Website */
.accordion {
  background-color: #FFFFFF;
  border-radius: 5px;
  position: relative;
  padding: 2rem 3rem;
}
@media only screen and (max-width: 480px) {
  .accordion {
    padding: 2rem;
  }
}
.accordion + .accordion {
  margin-top: 2rem;
}
.accordion__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: unset;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
}
.accordion__icon {
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-out;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: rotate(0);
  font-size: 1.75rem;
}
.--openAccordion .accordion__icon {
  transform: rotate(90deg);
}
.accordion__header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}
.accordion__header h3:not(:only-child) {
  margin-right: 1.5rem;
  margin-bottom: 0;
}
.accordion__content {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: auto;
}
.accordion__content p {
  opacity: 0.5;
  padding: 1rem 0 0;
}
.--openAccordion .accordion__content {
  width: 100%;
  height: auto;
  opacity: 1;
}
.back-to-top {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 30;
}
.back-to-top__circle {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00A7FF;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 0.7rem;
  cursor: pointer;
}
@media only screen and (max-width: 480px) {
  .back-to-top__circle {
    display: none !important;
  }
}
.back-to-top__circle > i {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  font-size: 1rem;
}
.back-to-top__circle > span {
  font-weight: 700;
  text-transform: uppercase;
}
.back-to-top__circle:hover,
.back-to-top__circle:focus {
  background-color: #0293EC;
}
.back-to-top__circle:hover i,
.back-to-top__circle:focus i {
  transform: translateY(-0.15rem);
}
.box {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: -2px 5px 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}
.box.--withButton {
  padding-bottom: calc(0.75rem + 2.75rem);
}
@media only screen and (max-width: 1280px) {
  .box.--withButton {
    padding-bottom: calc(0.75rem + 2rem);
  }
}
.box.--fullWidth {
  padding: 0;
}
.box.--solid {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 3.2rem;
  border-radius: 10px;
  height: 100%;
}
@media only screen and (max-width: 640px) {
  .box.--solid {
    padding: 5rem 2rem;
    border-radius: 0;
    justify-content: center;
  }
}
.box.--solid * {
  color: #FFFFFF;
}
.box.--dark {
  background-color: #021431;
}
.box.--medium {
  background-color: #0B43A0;
}
.box__inner {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.75rem;
  height: auto;
}
.box__inner.--medium {
  padding: 2rem 1rem;
}
.box__header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #F1F2F3;
  padding: 0.75rem;
}
.--solid .box__logo {
  width: auto;
  height: 35px;
  margin-bottom: 1rem;
}
.box__copy {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}
.box__copy p + h3 {
  margin-bottom: 0;
}
.box__button-wrapper {
  width: 100%;
}
.--solid .box__button-wrapper {
  margin-top: 5rem;
}
@media only screen and (max-width: 640px) {
  .--solid .box__button-wrapper {
    margin-top: 2rem;
  }
}
.--solid .box__button-wrapper .button {
  width: auto;
}
@media only screen and (max-width: 640px) {
  .--solid .box__button-wrapper .button {
    width: 100%;
  }
}
.box__button-wrapper .button {
  width: 100%;
}
.box__list {
  width: 100%;
}
.box__item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}
.box__icon-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.box__icon-wrapper.--mediumBlue {
  background-color: #0B43A0;
}
.box__icon {
  color: #FFFFFF;
}
.button {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: ease-out;
  border: none;
  box-shadow: none;
  margin: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  font-weight: 600;
  text-decoration: none;
  font-family: Karla, Arial, sans-serif;
}
.button:hover,
.button:focus,
.button:active {
  text-decoration: none;
}
.button.--primary {
  background-color: #00A7FF;
  color: #FFFFFF;
}
.button.--primary:hover,
.button.--primary:focus {
  background-color: #0293EC;
}
.button.--primary:active {
  background-color: #0760BB;
}
.button.--decorative {
  cursor: default;
  background-color: #00A7FF;
  color: #FFFFFF;
}
.button.--blue-transparent {
  background-color: transparent;
  color: #00A7FF;
}
.button.--blue-transparent:hover,
.button.--blue-transparent:focus {
  background-color: #0293EC;
  color: #FFFFFF;
}
.button.--blue-transparent:active {
  background-color: #0760BB;
  color: #FFFFFF;
}
.button.--transparent {
  background-color: transparent;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
.button.--transparent:hover,
.button.--transparent:focus {
  background-color: #FFFFFF;
}
.box.--dark .button.--transparent:hover,
.box.--dark .button.--transparent:focus {
  color: #021431;
}
.box.--medium .button.--transparent:hover,
.box.--medium .button.--transparent:focus {
  color: #0B43A0;
}
.button.--transparent:active {
  background-color: #e6e6e6;
}
.box.--dark .button.--transparent:active {
  color: #021431;
}
.box.--medium .button.--transparent:active {
  color: #0B43A0;
}
.button.--transparent-reverse {
  background-color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
.box.--dark .button.--transparent-reverse {
  color: #021431;
}
.box.--medium .button.--transparent-reverse {
  color: #0B43A0;
}
.button.--transparent-reverse:hover,
.button.--transparent-reverse:focus {
  background-color: transparent;
}
.box.--dark .button.--transparent-reverse:hover,
.box.--dark .button.--transparent-reverse:focus,
.box.--medium .button.--transparent-reverse:hover,
.box.--medium .button.--transparent-reverse:focus {
  color: #FFFFFF;
}
.button.--transparent-reverse:active {
  background-color: #e6e6e6;
}
.box.--dark .button.--transparent-reverse:active,
.box.--medium .button.--transparent-reverse:active {
  color: #FFFFFF;
}
.button.--inverted {
  background-color: #FFFFFF;
  color: #00A7FF;
  border: 2px solid #00A7FF;
}
.button.--inverted:hover,
.button.--inverted:focus {
  background-color: #00A7FF;
  color: #FFFFFF;
}
.button.--inverted:active {
  background-color: #0760BB;
  color: #FFFFFF;
  border-color: #0760BB;
}
.button.--invertedBrands {
  background-color: #FFFFFF;
  color: #0B43A0;
  border: 2px solid transparent;
}
.button.--invertedBrands:hover,
.button.--invertedBrands:focus {
  background-color: #0B43A0;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.button.--invertedBrands:active {
  background-color: #082f70;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.button.--login {
  background-color: #FFFFFF;
  color: #00A7FF;
}
.button.--login:hover,
.button.--login:focus {
  background-color: #F1F2F3;
}
.button.--login:active {
  background-color: #e3e5e7;
}
.button.--loginBrands {
  background-color: transparent;
  color: #FFFFFF;
}
.button.--loginBrands:hover,
.button.--loginBrands:focus {
  background-color: #FFFFFF;
  color: #0B43A0;
}
.button.--loginBrands:active {
  background-color: #FFFFFF;
  color: #0B43A0;
}
.button.--small {
  border-radius: 5px;
  padding: 0.3125rem 1.25rem;
  font-size: 0.75rem;
}
.button.--medium {
  border-radius: 10px;
  padding: 0.75rem 3.125rem;
  font-size: 0.875rem;
}
.button.--large {
  border-radius: 10px;
  padding: 0.875rem 2.5rem;
  font-size: 1rem;
}
@media only screen and (max-width: 480px) {
  .button.--large {
    width: 100%;
  }
}
.button.--inline {
  width: auto;
  margin-top: 2rem;
}
.button.--inline + .button.--inline {
  margin-left: 0.5rem;
}
@media only screen and (max-width: 480px) {
  .button.--inline {
    width: 100%;
  }
  .button.--inline + .button.--inline {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
.button.--hasInput {
  position: absolute;
  height: 40px;
  right: 0;
  bottom: 0;
  border-radius: 5px;
}
@media only screen and (max-width: 480px) {
  .button.--hasInput {
    position: static;
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }
}
.button.--center {
  margin: 0 auto;
}
.button__icon {
  margin-right: 0.5rem;
  font-size: inherit;
  color: inherit;
}
.button__icon:only-child {
  margin: 0;
}
.button__image {
  max-width: 1.125rem;
}
.button.--disabled,
.button[disabled],
.button--loading {
  cursor: not-allowed;
  pointer-events: none;
}
.button.--disabled,
.button[disabled] {
  opacity: 0.5;
}
.about-hero__wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1220px;
  padding: 2rem 0 2rem 2rem;
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .about-hero__wrapper {
    max-width: 640px;
    flex-flow: column;
    align-items: stretch;
  }
}
@media only screen and (max-width: 960px) and (max-height: 480px) {
  .about-hero__wrapper {
    padding-top: 0;
  }
}
.about-hero__textWrapper {
  flex: 0 1 30%;
}
@media only screen and (max-width: 1280px) {
  .about-hero__textWrapper {
    flex: 0 1 33%;
  }
}
@media only screen and (max-width: 1024px) {
  .about-hero__textWrapper {
    flex: 0 1 35%;
  }
}
@media only screen and (max-width: 960px) {
  .about-hero__textWrapper {
    flex: 1;
    order: 2;
    padding-right: 2rem;
  }
}
.about-hero__imageWrapper {
  flex: 1;
  margin-left: 7rem;
  margin-left: clamp(5rem, 7vw, 7rem);
}
@media only screen and (max-width: 960px) {
  .about-hero__imageWrapper {
    flex: 1;
    order: 1;
    margin: 0 0 2rem;
  }
}
.callout {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.callout__column {
  flex: 1;
}
.callout__column:not(:first-child) {
  margin-left: 1rem;
}
.callout__large {
  font-size: 2.25rem;
  line-height: 1.28;
  margin-bottom: 0.25rem;
  font-weight: 700;
}
.callout__text {
  margin: 0;
  line-height: 1.3;
}
.callout__text:not(:first-of-type) {
  margin-top: 0;
}
.carousel {
  width: 100%;
  margin: auto;
  /*
		Modifiers 
	*/
  /*
		Local mixins
	*/
}
.carousel__wrapper {
  width: 100%;
}
.carousel__imageWrapper {
  position: relative;
}
.carousel__image {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.carousel__content {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 2rem;
}
.carousel__statsWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
}
.carousel__stats {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  max-width: 145px;
}
.carousel__stats:not(:last-child) {
  margin-right: 4rem;
}
@media only screen and (max-width: 960px) {
  .carousel__stats:not(:last-child) {
    margin: 0;
  }
}
@media only screen and (max-width: 960px) {
  .carousel__stats:nth-child(n+2) {
    display: none;
  }
}
.carousel__stats h2 {
  color: #00A7FF;
}
@media only screen and (max-width: 480px) {
  .carousel__stats h2:not(:only-child) {
    margin-bottom: 0.5rem;
  }
}
.carousel__stats h6 {
  text-transform: none;
  line-height: 1.3;
}
.carousel.--vertical {
  display: flex;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: center;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical {
    flex-flow: column;
  }
}
.carousel.--vertical .carousel__wrapper {
  width: 50%;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__wrapper {
    width: 100%;
    padding: 0;
  }
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__wrapper:first-child {
    margin-bottom: 2rem;
  }
}
.carousel.--vertical .carousel__for,
.carousel.--vertical .carousel__nav {
  height: 100%;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__for,
  .carousel.--vertical .carousel__nav {
    height: auto;
  }
}
.carousel.--vertical .carousel__for {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  padding-right: 4rem;
  /* 
				Slick overrides
			*/
}
.carousel.--vertical .carousel__for:focus {
  outline: 0;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__for {
    flex-flow: row nowrap;
    max-width: min(60vw, 25rem);
    margin: auto;
    padding: 0;
  }
}
@media only screen and (max-width: 640px) {
  .carousel.--vertical .carousel__for {
    max-width: 100%;
  }
}
.carousel.--vertical .carousel__for .slick-list {
  height: 100%;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__for .slick-list {
    height: auto;
    padding: 1rem;
  }
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__for .slick-track {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
}
.carousel.--vertical .carousel__for .slick-slide {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  outline: 0;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__for .slick-slide {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    padding: 0;
    height: auto;
  }
}
.carousel.--vertical .carousel__nav {
  cursor: pointer;
  /* 
				Slick overrides
			*/
}
.carousel.--vertical .carousel__nav h3,
.carousel.--vertical .carousel__nav p {
  opacity: 0.5;
}
.carousel.--vertical .carousel__nav h3 {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  text-transform: uppercase;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__nav {
    cursor: default;
  }
}
.carousel.--vertical .carousel__nav.slick-dotted.slick-slider {
  margin-bottom: 1.5rem;
}
.carousel.--vertical .carousel__nav .slick-track {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  border-left: 5px solid rgba(2, 20, 49, 0.04);
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__nav .slick-track {
    border: none;
    flex-flow: row nowrap;
  }
}
@media only screen and (min-width: 961px) {
  .carousel.--vertical .carousel__nav .slick-track,
  .carousel.--vertical .carousel__nav .slick-list {
    transform: translate3d(0, 0, 0) !important;
    height: 100% !important;
  }
}
.carousel.--vertical .carousel__nav .slick-slide {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  border: none;
  padding: 2rem;
  position: relative;
  height: 100%;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__nav .slick-slide {
    align-items: center;
    text-align: center;
    padding: 0;
    height: auto;
  }
}
.carousel.--vertical .carousel__nav .slick-slide:focus {
  outline: none;
}
.carousel.--vertical .carousel__nav .slick-slide:focus h3 {
  opacity: 1;
}
.carousel.--vertical .carousel__nav .slick-slide:focus p {
  opacity: 0.75;
}
.carousel.--vertical .carousel__nav .slick-slide:focus::before {
  background-color: #00A7FF;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__nav .slick-slide:focus::before {
    background-color: transparent;
  }
}
.carousel.--vertical .carousel__nav .slick-slide::before {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  content: '';
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 5px;
  left: -5px;
  background-color: transparent;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__nav .slick-slide::before {
    display: none;
  }
}
.carousel.--vertical .carousel__nav .slick-slide.slick-current::before {
  background-color: #00A7FF;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__nav .slick-slide.slick-current::before {
    background-color: transparent;
  }
}
.carousel.--vertical .carousel__nav .slick-slide.slick-current h3 {
  opacity: 1;
}
.carousel.--vertical .carousel__nav .slick-slide.slick-current p {
  opacity: 0.75;
}
.carousel.--vertical .carousel__dots {
  display: none;
}
@media only screen and (max-width: 960px) {
  .carousel.--vertical .carousel__dots {
    max-width: min(60vw, 25rem);
    margin: auto;
    display: block;
  }
}
.carousel.--banner {
  /* 
			Slick overrides
		*/
}
.carousel.--banner h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.carousel.--banner .slick-dotted.slick-slider {
  margin-bottom: 0;
}
.carousel.--banner .slick-slide {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: border-color;
  transition-timing-function: ease-out;
  border: 3px solid;
  border-color: #F1F2F3;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  height: auto;
}
.carousel.--banner .slick-slide:focus {
  outline: 0;
  border-color: #00A7FF;
}
.carousel.--banner .slick-slide > div {
  flex: 1;
}
@media only screen and (max-width: 960px) {
  .carousel.--banner .slick-slide > div {
    flex: unset;
  }
}
@media only screen and (max-width: 960px) {
  .carousel.--banner .slick-slide {
    flex-flow: column;
    height: auto;
  }
}
.carousel.--banner .slick-dots {
  margin: 5rem auto 0;
  width: 40%;
}
.carousel.--gallery {
  margin-top: 5rem;
  /* 
			Slick overrides
		*/
}
@media only screen and (max-width: 480px) {
  .carousel.--gallery {
    margin-top: 2rem;
  }
}
.carousel.--gallery .carousel__imageWrapper {
  margin: 0.5rem;
}
.carousel.--gallery .slick-slide {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: border-color;
  transition-timing-function: ease-out;
  border: 3px solid;
  border-color: #FFFFFF;
  cursor: pointer;
  border-radius: 30px;
  height: auto;
}
.carousel.--gallery .slick-slide:focus {
  outline: 0;
  border-color: #00A7FF;
}
.carousel .slick-dots {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: static;
}
.carousel .slick-dots > li {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  background-color: rgba(2, 20, 49, 0.04);
  width: auto;
  height: 5px;
  border-radius: 10px;
  flex: 1;
}
.carousel .slick-dots > li.slick-active {
  background-color: #00A7FF;
}
.carousel .slick-dots > li button {
  width: 100%;
  height: 100%;
  padding: 0;
}
.carousel .slick-dots > li button::before {
  color: transparent;
  opacity: 0;
  width: 0;
  height: 0;
  content: '';
}
.carousel .slick-prev,
.carousel .slick-next {
  width: 1.5rem;
  height: 1.5rem;
}
.carousel .slick-prev::before,
.carousel .slick-next::before {
  color: #99A0AC;
  font-family: 'Font Awesome 5 Pro';
  font-size: 1.5rem;
}
.carousel .slick-prev::before {
  content: '\f053';
}
.carousel .slick-next::before {
  content: '\f054';
}
.footer {
  background-color: #021431;
  color: #FFFFFF;
}
@media print {
  .footer {
    display: none;
  }
}
.footer .inner {
  padding: 2rem;
}
@media only screen and (max-width: 960px) {
  .footer .inner {
    max-width: 100%;
  }
}
.footer__list * {
  color: #FFFFFF;
}
.footer__header {
  font-weight: 600;
  margin-bottom: 0.75rem;
  display: inline-block;
}
.footer__item a {
  color: #FFFFFF;
}
.footer__item a:hover {
  color: #FFFFFF;
}
.footer__icon-wrapper {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 0.25rem;
}
.footer__icon-wrapper > a {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  border: 2px solid #021431;
  border-radius: 50%;
}
.footer__icon-wrapper > a:hover {
  background-color: #021431;
  border-color: #FFFFFF;
  text-decoration: none;
}
.footer__icon-wrapper > a:hover .footer__icon {
  color: #FFFFFF;
}
.footer__icon {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: ease-out;
  font-size: 0.875rem;
  margin-top: 2px;
  color: #021431;
}
.footer__icon.fa-instagram {
  margin-left: 1px;
}
.footer__logo-wrapper {
  display: block;
}
.footer__logo {
  max-width: 125px;
  width: 100%;
  height: auto;
}
.form {
  width: 100%;
  /* 
		Prevent input zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max 
	*/
}
.form * {
  box-sizing: border-box;
  font-family: Karla, Arial, sans-serif;
}
.form__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  text-align: left;
}
@media only screen and (max-width: 640px) {
  .form__row {
    flex-flow: column;
    margin-bottom: 0;
  }
}
.form__column {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.form__column:not(:first-child) {
  margin-left: 1rem;
}
@media only screen and (max-width: 640px) {
  .form__column:not(:first-child) {
    margin-left: 0;
  }
}
@media only screen and (max-width: 640px) {
  .form__column {
    width: 100%;
    flex: auto;
    margin-bottom: 1rem;
  }
}
.form__label {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  color: #021431;
  font-size: 0.75rem;
  padding-left: 0.5rem;
}
.form__input-wrapper {
  display: flex;
  flex-flow: column-reverse;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 3.75rem;
  position: relative;
}
.form__input-wrapper.--hasIcon {
  position: relative;
}
.form__input-wrapper.--hasButton {
  overflow: initial;
}
.form__input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid rgba(2, 20, 49, 0.2);
  border-radius: 5px;
  padding: 0.5rem;
  height: 40px;
  line-height: 1;
  background-color: #FFFFFF;
  font-size: 100%;
  font-weight: 400;
  color: #021431;
  -webkit-appearance: none;
  /*
			Floating Labels
		*/
}
.form__input:focus {
  outline: none;
  border-color: #00A7FF;
}
.form__input.--error {
  border-color: #F2675B;
}
.--hasIcon .form__input {
  padding: 0.5rem 2rem 0.5rem 0.5rem;
}
.--hasButton .form__input {
  padding-right: 10rem;
}
@media only screen and (max-width: 480px) {
  .--hasButton .form__input {
    padding-right: 0.5rem;
  }
}
.form__input:placeholder-shown + .form__label {
  cursor: text;
  max-width: calc(100% - .5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translateY(1.8rem);
  font-size: 1rem;
}
.form__input::-webkit-input-placeholder,
.form__input::placeholder {
  opacity: 0;
}
.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
  -webkit-text-fill-color: #021431 !important;
}
.form__input:-webkit-autofill::first-line,
.form__input:-webkit-autofill:hover::first-line,
.form__input:-webkit-autofill:focus::first-line {
  font-size: 1rem;
  font-family: Karla, Arial, sans-serif;
}
.form__input:focus + .form__label,
.form__input:not(:placeholder-shown) + .form__label,
.form__input:-webkit-autofill + .form__label {
  transform: translateY(-3px);
  cursor: default;
  font-size: 0.75rem;
  padding-left: 0.3rem;
}
.form__input:focus {
  outline: 0;
  border-color: #00A7FF;
}
.form__input:focus + .form__label {
  color: #021431;
}
.form__input:not(:placeholder-shown) {
  border-color: #B7BBC0;
}
.form__input:not(:placeholder-shown) + .form__label {
  color: #021431;
}
.form__textarea {
  width: 100%;
  border: 1px solid rgba(2, 20, 49, 0.2);
  border-radius: 5px;
  padding: 0.5rem;
  line-height: 1.5;
  background-color: #FFFFFF;
  font-size: 100%;
  font-weight: 400;
  color: #021431;
  resize: none;
}
.--hasIcon .form__icon-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  left: unset;
}
.form__icon {
  color: #00A7FF;
  font-size: 1rem;
  align-self: flex-end;
}
.--hasIcon .form__icon {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: ease-out;
  align-self: center;
  line-height: normal;
}
.form__icon.--eye {
  color: #99A0AC;
}
.form__icon.--success {
  color: #00EDA5;
}
.form__caption {
  margin-top: 0.25rem;
}
.form__label.--error,
.form__icon.--error {
  color: #F2675B;
}
.form__select {
  border: 1px solid rgba(2, 20, 49, 0.2);
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 0.5rem 1.75rem 0.5rem 0.5rem;
  font-size: 100%;
  appearance: none;
  cursor: pointer;
  background-color: #FFFFFF;
}
@media screen and (device-aspect-ratio: 0.5625) {
  .form select,
  .form textarea,
  .form input[type="text"],
  .form input[type="password"],
  .form input[type="datetime"],
  .form input[type="datetime-local"],
  .form input[type="date"],
  .form input[type="month"],
  .form input[type="time"],
  .form input[type="week"],
  .form input[type="number"],
  .form input[type="email"],
  .form input[type="tel"],
  .form input[type="url"] {
    font-size: 16px;
  }
}
.full-image-stack {
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: relative;
  overflow: hidden;
}
.full-image-stack > div {
  position: absolute;
  bottom: 0;
  right: 0;
  height: auto;
  max-height: 100%;
}
.full-image-stack > div > img {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  width: 100%;
}
@media only screen and (max-width: 960px) {
  [data-section="business-hero"] .full-image-stack > div > img {
    width: 110%;
  }
}
@media only screen and (max-width: 768px) {
  [data-section="business-hero"] .full-image-stack > div > img {
    width: 125%;
  }
}
@media only screen and (max-width: 640px) {
  [data-section="business-hero"] .full-image-stack > div > img {
    width: 140%;
  }
}
@media only screen and (max-width: 480px) {
  [data-section="business-hero"] .full-image-stack > div > img {
    width: 150%;
  }
}
.full-image-stack [data-layer] {
  position: absolute;
}
[data-section="business-hero"] .full-image-stack [data-layer="bottom"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-hero-bottom.png');
  transform: translate(0, 0);
}
[data-section="business-hero"] .full-image-stack [data-layer="middle"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-hero-middle.png');
  transform: translate(0, 12%);
}
[data-section="business-hero"] .full-image-stack [data-layer="top"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-hero-top.png');
  transform: translate(0, 28%);
}
[data-section="business-hero"] .full-image-stack [data-layer="text"] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 960px) {
  [data-section="business-hero"] .full-image-stack [data-layer="text"] {
    transform: none;
    position: static;
  }
}
@media only screen and (max-width: 960px) and (max-height: 480px) {
  [data-section="business-hero"] .full-image-stack [data-layer="text"] {
    padding-top: 0;
  }
}
.full-image-stack__image {
  background-size: contain;
  background-position: bottom right;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 960px) {
  .full-image-stack__image {
    background-size: cover;
  }
}
@media only screen and (max-width: 960px) and (max-height: 480px) {
  .full-image-stack__image {
    background-position: top right;
  }
}
.how-it-works-glider-wrapper .glider-prev,
.testimonials-glider-wrapper .glider-prev,
.spotlight-glider-wrapper .glider-prev,
.how-it-works-glider-wrapper .glider-next,
.testimonials-glider-wrapper .glider-next,
.spotlight-glider-wrapper .glider-next {
  background-color: white;
  width: 4.875rem;
  height: 4.875rem;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  color: #021431;
  opacity: 1;
  top: 42%;
  font-size: 1.125rem;
}
.how-it-works-glider-wrapper .glider-prev,
.testimonials-glider-wrapper .glider-prev,
.spotlight-glider-wrapper .glider-prev {
  left: -2.4375rem;
}
.how-it-works-glider-wrapper .glider-next,
.testimonials-glider-wrapper .glider-next,
.spotlight-glider-wrapper .glider-next {
  right: -2.4375rem;
}
.how-it-works-glider-wrapper .glider-track,
.testimonials-glider-wrapper .glider-track,
.spotlight-glider-wrapper .glider-track {
  gap: 1.25rem;
}
.glider-contain {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.glider {
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  transform: translateZ(0);
}
.glider-track {
  transform: translateZ(0);
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 1;
}
.glider.draggable {
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}
.glider.draggable .glider-slide img {
  user-select: none;
  pointer-events: none;
}
.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.glider-slide {
  user-select: none;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.glider-slide img {
  max-width: 100%;
}
.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}
.glider-next,
.glider-prev {
  user-select: none;
  position: absolute;
  outline: 0;
  background: 0 0;
  padding: 0;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  left: -23px;
  border: 0;
  top: 30%;
  cursor: pointer;
  color: #666;
  opacity: 1;
  line-height: 1;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.glider-next:focus,
.glider-next:hover,
.glider-prev:focus,
.glider-prev:hover {
  color: #a89cc8;
}
.glider-next {
  right: -23px;
  left: auto;
}
.glider-next.disabled,
.glider-prev.disabled {
  opacity: 0.25;
  color: #666;
  cursor: default;
}
.glider-slide {
  min-width: 150px;
}
.glider-hide {
  opacity: 0;
}
.glider-dots {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}
.glider-dot {
  border: 0;
  padding: 0;
  user-select: none;
  outline: 0;
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: #ccc;
  width: 12px;
  height: 12px;
  margin: 7px;
}
.glider-dot.active,
.glider-dot:focus,
.glider-dot:hover {
  background: #a89cc8;
}
@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }
  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 99px;
    background-color: rgba(156, 156, 156, 0.25);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
  }
}
.goal-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 3rem 1rem 2rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* 
		Hover state 
	*/
}
@media only screen and (max-width: 640px) {
  .goal-box {
    padding: 1rem;
    flex-flow: row nowrap;
  }
  .goal-box > [data-image] {
    flex: 1;
  }
  .goal-box > h4 {
    flex: 2;
  }
}
.goal-box [data-image] {
  width: 75%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 640px) {
  .goal-box [data-image] {
    margin: 0 1rem 0 0;
  }
}
.goal-box [data-image="plane"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-plane.svg');
  width: 55%;
}
@media only screen and (max-width: 640px) {
  .goal-box [data-image="plane"] {
    height: 65%;
  }
}
.goal-box [data-image="giftcard"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-giftcard.svg');
  width: 45%;
}
@media only screen and (max-width: 640px) {
  .goal-box [data-image="giftcard"] {
    height: 80%;
  }
}
.goal-box [data-image="money"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-money.svg');
  width: 32%;
}
@media only screen and (max-width: 640px) {
  .goal-box [data-image="money"] {
    height: 85%;
  }
}
.goal-box [data-image="stars"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-stars.svg');
}
.goal-box:hover {
  transform: scale(1.01);
  box-shadow: -2px 5px 15px rgba(0, 0, 0, 0.15);
}
.goal-box:hover [data-image="plane"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-plane-hover.svg');
  width: 80%;
}
@media only screen and (max-width: 640px) {
  .goal-box:hover [data-image="plane"] {
    height: 60%;
  }
}
.goal-box:hover [data-image="giftcard"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-giftcard-hover.svg');
  width: 65%;
}
@media only screen and (max-width: 640px) {
  .goal-box:hover [data-image="giftcard"] {
    height: 100%;
  }
}
.goal-box:hover [data-image="money"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-money-hover.svg');
  width: 100%;
}
@media only screen and (max-width: 640px) {
  .goal-box:hover [data-image="money"] {
    height: 100%;
  }
}
.goal-box:hover [data-image="stars"] {
  background-image: url('https://static.prizeout.com/website-redesign/images/business-goals-stars-hover.svg');
}
.gov-hero {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  background-image: url('https://assets.prizeout.com/gov/gov-hero.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 657px;
  margin-top: 67px;
}
@media only screen and (max-width: 960px) {
  .gov-hero {
    margin-top: 0;
  }
}
.gov-hero__inner {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 2rem;
}
@media only screen and (max-width: 960px) {
  .gov-hero__inner {
    max-width: 640px;
  }
}
.gov-hero__message {
  background-color: #FFFFFF;
  border-radius: 20px;
  z-index: 2;
  width: 45%;
  padding: 2.5rem;
}
@media only screen and (max-width: 960px) {
  .gov-hero__message {
    width: 100%;
  }
}
.gov-hero__message button {
  margin-top: 1.5rem;
}
.gov-help-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width: 640px) {
  .gov-help-item {
    flex-flow: row nowrap;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    padding: 0.75rem 1rem;
  }
}
.gov-help-item__icon {
  max-width: 7.5rem;
  height: auto;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 640px) {
  .gov-help-item__icon {
    max-width: 3.375rem;
    margin: 0 1rem 0 0;
  }
}
.gov-help-item__text {
  text-align: center;
  line-height: 1.2;
}
.grid {
  display: grid;
  align-items: center;
  justify-items: stretch;
  width: 100%;
  gap: 0;
  /*
		Custom grids
	*/
}
.grid.--oneCol {
  grid-template-columns: 1fr;
}
.grid.--twoCol {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media only screen and (max-width: 960px) {
  .grid.--twoCol {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 2rem;
  }
}
[data-section="cta-boxes"] .grid.--twoCol {
  grid-auto-rows: 1fr;
}
@media only screen and (max-width: 640px) {
  [data-section="cta-boxes"] .grid.--twoCol {
    gap: 0;
  }
}
[data-section="brands-hero"] .grid.--twoCol {
  gap: 4rem;
}
@media only screen and (max-width: 960px) and (max-height: 480px) {
  [data-section="brands-hero"] .grid.--twoCol {
    padding-top: 0;
  }
}
.grid.--threeCol {
  grid-template-columns: repeat(3, 1fr);
}
@media only screen and (max-width: 960px) {
  .grid.--threeCol {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
[data-section="about-values"] .grid.--threeCol,
[data-section="about-benefits"] .grid.--threeCol {
  gap: 4rem;
  align-items: start;
}
@media only screen and (max-width: 960px) {
  [data-section="about-benefits"] .grid.--threeCol {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 640px) {
  [data-section="about-benefits"] .grid.--threeCol {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
.grid.--fourCol {
  grid-template-columns: repeat(4, 1fr);
}
.grid.--fiveCol {
  grid-template-columns: repeat(5, 1fr);
}
.grid.--gap-4-start {
  gap: 4rem;
  align-items: start;
}
@media only screen and (max-width: 960px) {
  .grid.--hero .grid__item[text-block] {
    max-width: 640px;
    margin: 0 auto;
    padding: 2rem;
    z-index: 20;
  }
}
@media only screen and (max-width: 960px) and (max-height: 480px) {
  .grid.--hero .grid__item[text-block] {
    padding-top: 0;
  }
}
.grid.--preFooter {
  grid-template-columns: 1fr 42% 1fr;
  gap: 1.5rem;
}
@media only screen and (max-width: 1280px) {
  .grid.--preFooter {
    grid-template-columns: 1fr;
    width: 60%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 960px) {
  .grid.--preFooter {
    width: 75%;
  }
}
@media only screen and (max-width: 768px) {
  .grid.--preFooter {
    width: 100%;
  }
}
.grid.--footer {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  grid-template-columns: 1fr 63% 1fr;
  gap: 1.5rem;
  align-items: start;
}
@media only screen and (max-width: 960px) {
  .grid.--footer {
    gap: 1.5rem;
    grid-template-columns: 1fr;
  }
}
.grid.--footer .grid.--threeCol {
  align-items: start;
  justify-items: center;
}
@media only screen and (max-width: 960px) {
  .grid.--footer .grid.--threeCol {
    grid-template-columns: 1fr;
    justify-items: start;
    gap: 1.5rem;
  }
}
.grid.--goals,
.grid.--gov-help {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 17.375rem;
  gap: 1rem;
  align-items: stretch;
  margin-top: 3rem;
}
@media only screen and (max-width: 960px) {
  .grid.--goals,
  .grid.--gov-help {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 14rem);
  }
}
@media only screen and (max-width: 640px) {
  .grid.--goals,
  .grid.--gov-help {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 5rem);
    margin-top: 2rem;
  }
}
.grid.--gov-help {
  grid-template-rows: auto;
}
@media only screen and (max-width: 640px) {
  .grid.--gov-help {
    margin-bottom: 4rem;
  }
}
@media only screen and (max-width: 480px) {
  .grid.--gov-help {
    margin-bottom: 0;
  }
}
.grid.--table {
  align-items: stretch;
  border: 1px solid rgba(2, 20, 49, 0.1);
  overflow-x: auto;
  border-radius: 10px;
  margin: 2rem 0;
}
.grid.--table .grid__item {
  padding: 1rem;
  border-right: 1px solid rgba(2, 20, 49, 0.1);
  border-bottom: 1px solid rgba(2, 20, 49, 0.1);
}
.grid.--table.--threeCol .grid__item:nth-child(6n),
.grid.--table.--threeCol .grid__item:nth-child(6n-1),
.grid.--table.--threeCol .grid__item:nth-child(6n-2) {
  background-color: #F1F2F3;
}
.grid.--table.--threeCol .grid__item:nth-child(3n) {
  border-right: none;
}
.grid.--table.--threeCol .grid__item:nth-last-child(-n+3) {
  border-bottom: none;
}
@media only screen and (max-width: 960px) {
  .grid.--table.--threeCol {
    grid-template-columns: repeat(3, 1fr);
  }
}
.grid.--table.--fourCol .grid__item:nth-child(8n),
.grid.--table.--fourCol .grid__item:nth-child(8n-1),
.grid.--table.--fourCol .grid__item:nth-child(8n-2),
.grid.--table.--fourCol .grid__item:nth-child(8n-3) {
  background-color: #F1F2F3;
}
.grid.--table.--fourCol .grid__item:nth-child(4n) {
  border-right: none;
}
.grid.--table.--fourCol .grid__item:nth-last-child(-n+4) {
  border-bottom: none;
}
.grid__item {
  position: relative;
  height: auto;
}
@media only screen and (max-width: 960px) {
  .grid__item[image-block] {
    grid-row-start: 2;
  }
}
.grid__item.--justifyEnd {
  justify-self: end;
}
@media only screen and (max-width: 960px) {
  .grid__item.--justifyEnd {
    justify-self: start;
  }
}
[data-section="business-goals"] .grid__item,
[data-section="cta-boxes"] .grid__item {
  height: 100%;
}
.--preFooter .grid__item[data-group="logos-mobile"] {
  display: none;
}
@media only screen and (max-width: 1280px) {
  .--preFooter .grid__item[data-group="logos1"],
  .--preFooter .grid__item[data-group="logos2"] {
    display: none;
  }
  .--preFooter .grid__item[data-group="logos-mobile"] {
    display: block;
    order: 1;
  }
  .--preFooter .grid__item[data-group="text"] {
    order: 2;
    margin-top: 2rem;
  }
}
.hamburger {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  display: none;
  overflow: visible;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border: 0;
  background-color: transparent;
}
@media only screen and (max-width: 960px) {
  .hamburger {
    display: inline-block;
  }
}
.hamburger.--isActive .hamburger__inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger.--isActive .hamburger__inner::before,
.hamburger.--isActive .hamburger__inner::after {
  background-color: #021431;
}
.--dark .hamburger.--isActive .hamburger__inner::before,
.--dark .hamburger.--isActive .hamburger__inner::after {
  background-color: #FFFFFF;
}
.hamburger.--isActive .hamburger__inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.hamburger.--isActive .hamburger__inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
  width: 100%;
}
.hamburger__wrapper {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 20px;
}
.hamburger__inner,
.hamburger__inner::before,
.hamburger__inner::after {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  position: absolute;
  width: 24px;
  height: 2px;
  border-radius: 3px;
  background-color: #021431;
}
.--dark .hamburger__inner,
.--dark .hamburger__inner::before,
.--dark .hamburger__inner::after {
  background-color: #FFFFFF;
}
.hamburger__inner {
  top: 2px;
  display: block;
  margin-top: -2px;
}
.hamburger__inner::before,
.hamburger__inner::after {
  display: block;
  content: '';
}
.hamburger__inner::before {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  top: 8px;
}
.hamburger__inner::after {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  top: 16px;
  width: 50%;
  right: 0;
}
.hamburger__menu {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  transform: translateX(100%);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas: "top" "bottom";
}
.hamburger__menu > .top {
  padding: 20px;
  padding-top: 100px;
  grid-area: top;
  justify-self: start;
  align-self: start;
}
.hamburger__menu > .top .hamburger__trigger .fa-angle-up {
  display: none;
}
.hamburger__menu > .top .hamburger__trigger .fa-angle-down {
  display: inline-block;
}
.hamburger__menu > .top .hamburger__trigger.--isActive .fa-angle-up {
  display: inline-block;
}
.hamburger__menu > .top .hamburger__trigger.--isActive .fa-angle-down {
  display: none;
}
.hamburger__menu > .top a {
  color: #021431;
  text-decoration: none !important;
}
.hamburger__menu > .top > ul {
  list-style-type: none;
}
.hamburger__menu > .top > ul > li {
  padding: 10px;
  padding-left: 20px;
}
.hamburger__menu > .top > ul > li a {
  font-size: 1rem;
  font-weight: 700;
}
.hamburger__menu > .top > ul > li a .fas {
  color: #00A7FF;
}
.hamburger__menu > .top > ul > li > ul {
  list-style-type: none;
  margin-top: 10px;
}
.hamburger__menu > .top > ul > li > ul > li {
  padding: 10px;
  padding-left: 20px;
}
.hamburger__menu > .top > ul > li > ul > li a {
  font-size: 1rem;
  font-weight: 500;
}
.hamburger__menu > .bottom {
  grid-area: bottom;
  justify-self: stretch;
  align-self: end;
  padding: 10px;
}
.hamburger__menu > .bottom > div {
  padding: 10px;
}
.hamburger__menu > .bottom a {
  display: block;
  text-align: center;
  width: 100%;
}
.hamburger__menu.--dark {
  background-color: #0B43A0;
}
.hamburger__menu.--dark > .top a {
  color: #ffffff;
}
.hamburger__item {
  width: 100%;
  max-width: 640px;
  text-align: center;
  padding: 0 1rem;
}
@media only screen and (max-width: 640px) {
  .hamburger__item {
    width: 80%;
  }
}
.hamburger__item:not(:last-child) {
  margin-bottom: 1.5rem;
}
.hamburger__item a {
  display: block;
}
@media only screen and (max-width: 960px) {
  .hamburger-open {
    position: fixed;
  }
  .hamburger-open .hamburger__menu {
    transform: translateX(0);
  }
}
.header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  background-color: #ffffff;
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  border-bottom: 1px solid #EDEEF1;
  height: 67px;
}
@media print {
  .header {
    display: none;
  }
}
.header__wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 100px 0.5rem 120px;
}
@media only screen and (max-width: 960px) {
  .header__wrapper.--small {
    padding: 1rem;
  }
}
.header__wrapper > div > a,
.header__wrapper > div > div {
  vertical-align: middle;
  margin-right: 2rem;
  text-decoration: none;
  display: inline-block;
}
.header__wrapper > div > a:last-child,
.header__wrapper > div > div:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 960px) {
  .header__wrapper > div > a,
  .header__wrapper > div > div {
    margin-right: 0;
  }
}
.header__wrapper > div > a.link,
.header__wrapper > div > div.link {
  color: #021431;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.header__wrapper > div > a.link:hover,
.header__wrapper > div > div.link:hover {
  color: #00A7FF;
}
.--dark .header__wrapper > div > a.link,
.--dark .header__wrapper > div > div.link {
  color: #ffffff;
}
.--dark .header__wrapper > div > a.link:hover,
.--dark .header__wrapper > div > div.link:hover {
  color: #ffffff;
}
.header__wrapper > div > a .fas:not(.fa-user-circle),
.header__wrapper > div > div .fas:not(.fa-user-circle) {
  color: #00A7FF;
}
.header__logo {
  max-width: 9.375rem;
  width: 100%;
  height: auto;
  margin-right: 30px;
}
@media only screen and (max-width: 960px) {
  .header__logo {
    margin-right: 1rem;
  }
}
.header .--only-desktop {
  display: inline-block;
}
@media only screen and (max-width: 960px) {
  .header .--only-desktop {
    display: none;
  }
}
.header .--only-mobile {
  display: none;
}
@media only screen and (max-width: 960px) {
  .header .--only-mobile {
    display: inline-block;
  }
}
.header.--dark {
  background-color: #0B43A0;
  border-bottom: none;
}
/* Hover menu for the header. Reusable. */
.hover-menu {
  position: relative;
}
.hover-menu .hover-menu-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transform: scale(0.5, 0);
  opacity: 0;
  z-index: 50000;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.hover-menu .hover-menu-container > div {
  position: relative;
}
.hover-menu .hover-menu-container > div > .menu {
  position: absolute;
  top: 0;
  left: -13px;
  z-index: 50000;
  padding-top: 15px;
}
.hover-menu .hover-menu-container > div > .menu > div {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
.hover-menu .hover-menu-container > div > .menu > div > a,
.hover-menu .hover-menu-container > div > .menu > div > div {
  white-space: nowrap;
  color: #021431;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px;
  text-align: left;
  border-radius: 7px;
  display: block;
  margin-top: 10px;
  text-decoration: none;
  padding-right: 40px;
  background-color: transparent;
  transition: 0.3s ease-out background-color;
}
.hover-menu .hover-menu-container > div > .menu > div > a:first-child,
.hover-menu .hover-menu-container > div > .menu > div > div:first-child {
  margin-top: 0;
}
.hover-menu .hover-menu-container > div > .menu > div > a:hover,
.hover-menu .hover-menu-container > div > .menu > div > div:hover {
  background-color: #BFE9FF;
}
.hover-menu .hover-menu-container > div > .menu > div > a.sep,
.hover-menu .hover-menu-container > div > .menu > div > div.sep {
  height: 0px;
  margin: 4px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}
.hover-menu .hover-menu-container > div > .menu > div > a.no-hover:hover,
.hover-menu .hover-menu-container > div > .menu > div > div.no-hover:hover {
  background-color: transparent;
}
.hover-menu .hover-menu-container > div > .menu > div > a.selected,
.hover-menu .hover-menu-container > div > .menu > div > div.selected {
  background-color: #BFE9FF;
}
.hover-menu .hover-menu-container.left {
  left: 10px !important;
}
.hover-menu .hover-menu-container.hidden {
  display: none;
}
.hover-menu.right .hover-menu-container > div > .menu,
.hover-menu.right .hover-menu-container > span > .menu {
  right: -7px !important;
  left: auto !important;
}
.hover-menu .--on-hover {
  display: none;
}
.hover-menu .--on-out {
  display: inline-block;
}
.hover-menu:hover .hover-menu-container {
  transform: scale(1, 1);
  opacity: 1;
}
.hover-menu:hover .--on-hover {
  display: inline-block;
}
.hover-menu:hover .--on-out {
  display: none;
}
.hero__wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1220px;
  padding: 2rem;
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .hero__wrapper {
    max-width: 640px;
    flex-flow: column;
    align-items: stretch;
  }
}
@media only screen and (max-width: 960px) and (max-height: 480px) {
  .hero__wrapper {
    padding-top: 0;
  }
}
.hero__textWrapper {
  flex: 0 1 40%;
}
@media only screen and (max-width: 960px) {
  .hero__textWrapper {
    flex: 1;
    order: 2;
  }
}
.hero__imageWrapper {
  flex: 1;
  margin-left: 7rem;
  margin-left: clamp(5rem, 7vw, 7rem);
}
@media only screen and (max-width: 960px) {
  .hero__imageWrapper {
    flex: 1;
    order: 1;
    margin: 0 0 2rem;
  }
}
.hero__video {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 960px) {
  .hero.--switchImage .hero__textWrapper {
    order: 1;
    margin: 0 0 2rem;
  }
  .hero.--switchImage .hero__imageWrapper {
    order: 2;
    margin: 0;
  }
}
.icon-text {
  display: flex;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}
.icon-text:not(:last-child) {
  margin-bottom: 1rem;
}
.icon-text.--column {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  margin: 0;
}
@media only screen and (max-width: 640px) {
  .icon-text.--column {
    align-items: center;
  }
}
.icon-text__icon {
  font-size: 1.5rem;
}
.icon-text__icon.--medium {
  font-size: 2.5rem;
}
.icon-text__icon.--blue {
  color: #00A7FF;
}
.icon-text__icon.--mediumBlue {
  color: #0B43A0;
}
.--column .icon-text__icon {
  margin-bottom: 1rem;
}
.icon-text__svg {
  width: 2rem;
}
.--column .icon-text__svg {
  width: auto;
  height: 6rem;
  margin-bottom: 2rem;
}
.icon-text__text {
  font-size: 1.125rem;
  margin-left: 0.75rem;
}
.icon-text__text.--block {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  margin-left: 1.25rem;
}
.icon-text__text.--block > p {
  margin: 0;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.--column .icon-text__text {
  margin-left: 0;
}
@media only screen and (max-width: 640px) {
  .--column .icon-text__text {
    text-align: center;
  }
}
.icon-text__text h3 {
  margin-bottom: 1rem;
}
.image-block {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  /*
		Slides within carousel-vertical-why (brands page)
	*/
  /*
		Local mixins
	*/
}
@media only screen and (max-width: 960px) {
  .image-block {
    max-width: calc(480px - 4rem);
    margin-top: 1rem;
  }
  .--hero .image-block {
    margin-top: 0;
  }
}
.image-block > div {
  grid-area: 1 / 1;
}
.image-block__top-layer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  height: auto;
  z-index: 20;
}
.image-block__top-layer > div {
  grid-area: 1 / 1;
}
.image-block__bottom-layer {
  height: auto;
  z-index: 10;
}
.image-block__button-layer {
  width: 100%;
  max-width: calc(100% - (0.75rem * 2));
  z-index: 30;
  margin: 0 auto;
  margin-bottom: 0.75rem;
}
@media only screen and (max-width: 1280px) {
  .image-block__button-layer .button.--medium {
    padding: 0.475rem;
  }
}
@media only screen and (max-width: 480px) {
  .image-block__button-layer .button.--medium {
    padding: 0.375rem;
  }
}
@media only screen and (max-width: 1280px) {
  .image-block__button-layer .button__image {
    max-width: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .image-block__button-layer .button__image {
    max-width: 0.75rem;
  }
}
@media only screen and (max-width: 360px) {
  .image-block__button-layer .button__image {
    max-width: 0.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .image-block__button-layer {
    max-width: calc(100% - (0.5rem * 2));
    margin-bottom: 0.5rem;
  }
}
.image-block__image-positioner {
  left: 0;
  position: relative;
  max-width: 100%;
  width: 100%;
}
.image-block__image {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
[data-section="0"] .image-block .image-block__bottom-layer,
[data-section="0"] .image-block .image-block__top-layer {
  max-width: 100%;
}
[data-section="0"] .image-block .image-block__bottom-layer .image-block__image,
[data-section="0"] .image-block .image-block__top-layer .image-block__image {
  background-position: top right;
}
[data-section="0"] .image-block .image-block__bottom-layer {
  filter: blur(5px);
}
@media only screen and (max-width: 960px) {
  [data-section="0"] .image-block {
    max-width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 480px) {
  [data-section="1"] .image-block .image-block__bottom-layer,
  [data-section="1"] .image-block .image-block__top-layer {
    max-width: 65% !important;
  }
}
@media only screen and (max-width: 480px) {
  [data-section="1"] .image-block .image-block__bottom-layer {
    margin-left: calc(100% - 65%) !important;
  }
}
.image-block[data-item="guaranteed-revenue"] .image-block__bottom-layer {
  max-width: 66%;
  margin-left: calc(100% - 66%);
}
.image-block[data-item="guaranteed-revenue"] .image-block__image {
  background-image: url("https://static.prizeout.com/website-redesign/images/about/why-prizeout/GuaranteedRevenue.png");
}
.image-block[data-item="guaranteed-revenue"] .image-block__top-layer {
  max-width: 46%;
}
.image-block[data-item="sticky-users"] .image-block__bottom-layer {
  max-width: 70%;
  margin-left: calc(100% - 70%);
}
.image-block[data-item="sticky-users"] .image-block__image {
  background-image: url("https://static.prizeout.com/website-redesign/images/about/why-prizeout/StickyUsers.png");
}
.image-block[data-item="sticky-users"] .image-block__top-layer {
  max-width: 50%;
}
.image-block[data-item="sticky-users"] .box__inner div {
  width: 100%;
}
.image-block[data-item="sticky-users"] .box__inner div > img {
  max-width: 60%;
  margin: 0 auto;
  z-index: 1;
}
.image-block[data-item="sticky-users"] .box__inner div > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-weight: 700;
  font-size: 2.75rem;
}
.image-block[data-item="cost-effective"] {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.image-block[data-item="cost-effective"] .grid {
  gap: 2rem;
}
.image-block[data-item="set-up"] .image-block__bottom-layer {
  max-width: 60%;
  margin-left: calc(100% - 60%);
}
.image-block[data-item="set-up"] .image-block__image {
  background-image: url("https://static.prizeout.com/website-redesign/images/about/why-prizeout/SimpleSetUp.png");
}
.image-block[data-item="set-up"] .image-block__top-layer {
  max-width: 58%;
}
@media only screen and (max-width: 960px) {
  [data-section="brands-carousel-vertical-why"] .image-block .image-block__bottom-layer {
    display: none;
  }
  [data-section="brands-carousel-vertical-why"] .image-block .image-block__top-layer {
    max-width: 100%;
  }
}
.image-tag {
  position: relative;
}
@media only screen and (max-width: 960px) {
  .image-tag:not(:last-child) {
    display: none;
  }
}
.image-tag__image {
  border-radius: 30px;
}
.image-tag__tag {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 167, 255, 0.8);
  border-radius: 67px;
  position: absolute;
  bottom: 1rem;
  right: -1rem;
  padding: 0.25rem 1rem;
}
.image-tag__icon,
.image-tag__text {
  color: #FFFFFF;
  font-size: 1rem;
}
.image-tag__text {
  margin-left: 0.5rem;
}
.image-text-stack {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
}
.image-text-stack__image {
  max-height: 12.5rem;
  margin: 0 auto;
}
.image-text-stack__circ {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #FFFFFF;
  border: 2px solid #00A7FF;
  margin: 3rem auto 1rem;
  border-radius: 50%;
}
.image-text-stack__number {
  font-size: 1rem;
  font-weight: 700;
  color: #00A7FF;
}
.image-text-stack__message {
  text-align: center;
}
.image-text-stack__heading {
  margin-bottom: 0.5rem;
}
@keyframes bounce {
  0%,
  25%,
  75%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.logos {
  position: relative;
  width: 100%;
  transform: translate3d(0, 0, 0);
}
.home .logos {
  min-height: 400px;
}
@media only screen and (max-width: 1280px) {
  .home .logos {
    min-height: initial;
  }
}
.brands .logos {
  min-height: 310px;
}
@media only screen and (max-width: 960px) {
  .brands .logos {
    min-height: initial;
    grid-row-start: 1;
  }
}
.logos__grid {
  display: grid;
  justify-items: center;
}
@media only screen and (max-width: 1280px) {
  .home .logos__grid {
    grid-template-columns: repeat(11, 1fr);
  }
}
@media only screen and (max-width: 960px) {
  .home .logos__grid,
  .brands .logos__grid {
    grid-template-columns: repeat(7, 1fr);
  }
}
.logos__logo {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: max-width;
  transition-timing-function: ease-out;
  position: absolute;
  max-width: 5.4375rem;
}
.logos__logo.--bbb {
  max-width: 135px;
}
.logos__logo.--dominos {
  max-width: 63px;
}
.logos__logo.--doordash {
  max-width: 70px;
}
.logos__logo.--hotels {
  max-width: 137px;
}
.logos__logo.--nordstrom {
  max-width: 89px;
}
.logos__logo.--lowes {
  max-width: 75px;
}
.logos__logo.--uber {
  max-width: 141px;
}
.logos__logo.--bww {
  max-width: 89px;
}
.home .logos__logo.--amazon {
  bottom: 0;
  left: 5%;
}
.home .logos__logo.--bbb {
  bottom: 0;
  right: 0;
}
.home .logos__logo.--dominos {
  top: 36%;
  right: 2%;
}
.home .logos__logo.--doordash {
  top: 24%;
  left: 4%;
}
.home .logos__logo.--hotels {
  bottom: 7%;
  right: 0;
}
.home .logos__logo.--nordstrom {
  bottom: 4%;
  left: -7%;
}
.home .logos__logo.--lowes {
  top: 35%;
  left: 37%;
}
.home .logos__logo.--dunkin {
  top: 0;
  right: 14%;
}
.home .logos__logo.--ulta {
  top: 14%;
  left: 0;
}
.home .logos__logo.--uber {
  top: 0;
  left: 40%;
}
.home .logos__logo.--bww {
  bottom: 32%;
  left: 28%;
}
@media only screen and (max-width: 1280px) {
  .home .logos__logo {
    position: static;
  }
  .home .logos__logo.--ulta {
    grid-column-start: 2;
    grid-row-start: 1;
  }
  .home .logos__logo.--dunkin {
    grid-column-start: 4;
    grid-row-start: 1;
  }
  .home .logos__logo.--lowes {
    grid-column-start: 6;
    grid-row-start: 1;
  }
  .home .logos__logo.--hotels {
    grid-column-start: 8;
    grid-row-start: 1;
  }
  .home .logos__logo.--amazon {
    grid-column-start: 10;
    grid-row-start: 1;
  }
  .home .logos__logo.--uber {
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .home .logos__logo.--dominos {
    grid-column-start: 3;
    grid-row-start: 2;
  }
  .home .logos__logo.--bww {
    grid-column-start: 5;
    grid-row-start: 2;
  }
  .home .logos__logo.--bbb {
    grid-column-start: 7;
    grid-row-start: 2;
  }
  .home .logos__logo.--nordstrom {
    grid-column-start: 9;
    grid-row-start: 2;
  }
  .home .logos__logo.--doordash {
    grid-column-start: 11;
    grid-row-start: 2;
  }
}
.brands .logos__logo.--ulta {
  top: 6%;
  left: 18%;
}
.brands .logos__logo.--dunkin {
  bottom: -45%;
  right: 0;
}
.brands .logos__logo.--lowes {
  top: 62%;
  left: 41%;
}
.brands .logos__logo.--hotels {
  bottom: -50%;
  left: -10%;
}
.brands .logos__logo.--amazon {
  bottom: -36%;
  left: 32%;
}
.brands .logos__logo.--uber {
  top: 23%;
  right: 3%;
}
.brands .logos__logo.--dominos {
  top: -50%;
  right: 0;
}
.brands .logos__logo.--bww {
  top: -30%;
  left: -8%;
}
.brands .logos__logo.--bbb {
  top: -31%;
  right: 26%;
}
.brands .logos__logo.--nordstrom {
  top: 55%;
  left: 2%;
}
.brands .logos__logo.--doordash {
  bottom: -10%;
  right: 17%;
}
@media only screen and (max-width: 960px) {
  .home .logos__logo,
  .brands .logos__logo {
    position: static;
  }
  .home .logos__logo.--ulta,
  .brands .logos__logo.--ulta {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .home .logos__logo.--dunkin,
  .brands .logos__logo.--dunkin {
    grid-column-start: 3;
    grid-row-start: 1;
  }
  .home .logos__logo.--lowes,
  .brands .logos__logo.--lowes {
    grid-column-start: 5;
    grid-row-start: 1;
  }
  .home .logos__logo.--hotels,
  .brands .logos__logo.--hotels {
    grid-column-start: 7;
    grid-row-start: 1;
  }
  .home .logos__logo.--amazon,
  .brands .logos__logo.--amazon {
    grid-column-start: 2;
    grid-row-start: 2;
  }
  .home .logos__logo.--uber,
  .brands .logos__logo.--uber {
    grid-column-start: 4;
    grid-row-start: 2;
  }
  .home .logos__logo.--dominos,
  .brands .logos__logo.--dominos {
    grid-column-start: 6;
    grid-row-start: 2;
  }
  .home .logos__logo.--bww,
  .brands .logos__logo.--bww {
    grid-column-start: 1;
    grid-row-start: 3;
  }
  .home .logos__logo.--bbb,
  .brands .logos__logo.--bbb {
    grid-column-start: 3;
    grid-row-start: 3;
  }
  .home .logos__logo.--nordstrom,
  .brands .logos__logo.--nordstrom {
    grid-column-start: 5;
    grid-row-start: 3;
  }
  .home .logos__logo.--doordash,
  .brands .logos__logo.--doordash {
    grid-column-start: 7;
    grid-row-start: 3;
  }
}
@media only screen and (max-width: 1280px) {
  .home .logos__logo[class*="--"] {
    max-width: 5rem;
  }
}
@media only screen and (max-width: 960px) {
  .brands .logos__logo[class*="--"] {
    max-width: 5rem;
  }
}
@media only screen and (max-width: 640px) {
  .home .logos__logo[class*="--"],
  .brands .logos__logo[class*="--"] {
    max-width: 3.875rem;
  }
}
.lottie-animation {
  position: relative;
  width: 100%;
  height: 100%;
}
.lottie-animation__gift {
  position: absolute;
  width: 126%;
  height: 126%;
  bottom: -26%;
  left: -13%;
  z-index: 1;
}
.lottie-animation__crown {
  position: absolute;
  z-index: 2;
  max-width: 60px;
  top: 45px;
  left: 39px;
}
.modal {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  z-index: 1001;
  width: min(85%, 750px);
  overflow: hidden;
  text-align: center;
  background-color: transparent;
  height: auto;
}
.modal .fa-exclamation-circle {
  display: none;
}
.modal .form {
  margin: 0;
}
.modal__header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  position: relative;
}
.brand-form .modal__header {
  background-color: #0B43A0;
}
.partner-form .modal__header,
.gov-form .modal__header {
  background-color: #021431;
}
.modal__logo {
  height: 30px;
  width: auto;
}
.modal__body {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  height: 100%;
  overflow-y: auto;
}
@media only screen and (max-width: 640px) {
  .modal__body {
    text-align: left;
  }
}
.modal__body > div {
  padding: 2.5rem;
}
@media only screen and (max-width: 480px) {
  .modal__body > div {
    padding: 1.5rem;
  }
}
.modal__copy {
  margin-bottom: 2.5rem;
}
.modal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.modal__close:hover .modal__icon {
  color: #e6e6e6;
}
.modal__close:hover .modal__icon.--dark {
  color: #4D5A6D;
}
.modal__icon {
  color: #FFFFFF;
  font-size: 1.5rem;
}
.modal__icon.--dark {
  color: #99A1AC;
}
.modal .button {
  margin: 2rem auto 0;
}
@media only screen and (max-width: 640px) {
  .modal .button {
    width: 100%;
    margin: 1rem auto 0;
  }
}
.modal__iframe {
  width: 100%;
  height: 100%;
  max-height: 95vh;
  overflow: auto;
}
.platform-logos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 1rem;
}
.platform-logos__logo {
  max-width: 125px;
}
.pre-footer {
  background-color: #F1F2F3;
  width: 100%;
  padding: 4rem 2rem;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .pre-footer {
    padding: 2rem;
  }
}
.pre-footer .form {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  text-align: left;
  width: 85%;
  margin: 2rem auto 0;
}
@media only screen and (max-width: 1280px) {
  .pre-footer .form {
    width: 90%;
  }
}
@media only screen and (max-width: 640px) {
  .pre-footer .form {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .pre-footer .form {
    margin-top: 1rem;
  }
}
.pre-footer #optin-form-confirm {
  width: 80%;
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
  background-color: #00A7FF;
  border-radius: 10px;
}
@media only screen and (max-width: 480px) {
  .pre-footer #optin-form-confirm {
    width: 100%;
    padding: 1.5rem;
  }
}
.pre-footer #optin-form-confirm > * {
  color: #FFFFFF;
}
.pre-footer #optin-form-confirm h4 {
  margin-bottom: 1rem;
}
.section {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  min-height: 100vh;
}
.section:first-child {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
}
@media only screen and (max-width: 960px) {
  .brands .section:first-child,
  .about .section:first-child,
  .localbrands .section:first-child,
  .garyvee .section:first-child,
  .partners .section:first-child {
    min-height: initial;
  }
}
.section.--no-margin {
  margin-top: 0;
}
.section.--pt-large {
  padding-top: 50px;
}
@media only screen and (max-width: 960px) {
  .section.--pt-large {
    padding-top: 0;
  }
}
.section.--adaptive {
  min-height: initial;
}
.section.--alternate {
  background-color: #F1F2F3;
}
.section.--alternate .grid__item[text-block] {
  grid-row-start: 1;
}
@media only screen and (max-width: 960px) {
  .section.--alternate .grid__item[text-block] {
    grid-row-start: 2;
  }
}
.section.--alternate .text-block {
  margin-right: 7rem;
  margin-left: 0;
}
.section.--altBackground {
  background-color: #F1F2F3;
}
@media only screen and (max-width: 960px) {
  .section.--altBackground .grid__item:first-child {
    order: 2;
  }
}
.section.--gradient-box-bottom {
  background: linear-gradient(180deg, #ffffff 40%, #e4f5ff 50%);
}
@media only screen and (max-width: 960px) {
  .section.--gradient-box-bottom {
    background: linear-gradient(180deg, #ffffff 20%, #e4f5ff 30%);
  }
}
.section.--dark {
  background-color: #021431;
}
.section.--light {
  background-color: #E4F5FF;
}
.section.--column {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.section__header {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.section__header.--center {
  text-align: center;
}
.section__header:not(:only-child) {
  margin-bottom: 3rem;
}
@media only screen and (max-width: 480px) {
  .section__header:not(:only-child) {
    margin-bottom: 2rem;
  }
}
.spotlight {
  align-content: start;
  background-color: #FFFFFF;
  border: 2px solid rgba(2, 20, 49, 0.04);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 42% auto;
  gap: 2.5rem;
  grid-template-areas: "image text";
}
@media only screen and (max-width: 1024px) {
  .spotlight {
    grid-template-columns: 46% auto;
    gap: 1.5rem;
  }
}
@media only screen and (max-width: 960px) {
  .spotlight {
    grid-template-columns: auto;
    grid-template-areas: "image" "text";
    border: none;
  }
}
.spotlight__image {
  grid-area: image;
}
.spotlight__image img {
  border-radius: 10px;
}
.spotlight__text {
  grid-area: text;
  align-self: center;
  padding: 1rem;
}
@media only screen and (max-width: 960px) {
  .spotlight__text {
    padding: 0;
  }
  .glider-track .spotlight__text {
    padding: 0 10px 10px;
  }
}
.spotlight__text.--space-between {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 2rem 2rem 0;
  align-self: stretch;
}
@media only screen and (max-width: 960px) {
  .spotlight__text.--space-between {
    padding: 0 1.5rem 1.5rem;
  }
}
.spotlight__text.--centered {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem 2rem 0;
}
@media only screen and (max-width: 960px) {
  .spotlight__text.--centered {
    padding: 0 1.5rem 1.5rem;
  }
}
.spotlight__logos {
  max-width: 14rem;
  margin-top: 1rem;
}
.taglist {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 960px) {
  .taglist {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
.taglist .tag {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 3.75rem auto;
  grid-template-areas: "icon label";
  margin: 1.25rem;
  width: auto;
  height: 4.5rem;
  border-radius: 0.75rem;
  cursor: default;
}
@media only screen and (max-width: 960px) {
  .taglist .tag {
    width: 100%;
    margin: 0.75rem;
  }
}
.taglist .tag > img {
  grid-area: icon;
  justify-self: end;
  align-self: center;
  max-width: 2rem;
  max-height: 2rem;
}
.taglist .tag > span {
  grid-area: label;
  justify-self: start;
  align-self: center;
  font-size: 1.2rem;
  padding: 0 1.5rem 0 0.875rem;
}
.terms {
  padding-top: 67px;
}
.terms p,
.terms li {
  text-align: justify;
  text-justify: inter-word;
}
.terms .grid * {
  text-align: left;
  text-justify: unset;
}
.terms h2,
.terms h3 {
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 1rem;
}
.terms h3 {
  text-transform: none;
  margin: 1rem 0;
}
.terms ul,
.terms ol,
.terms dl {
  padding-left: 1.125rem;
}
.terms li {
  list-style-type: revert;
  font-size: 1.125rem;
  margin-bottom: 2rem;
}
.terms li::marker {
  font-size: 1.125rem;
  font-weight: 700;
}
.terms li > ul,
.terms li > ol,
.terms li > dl {
  padding: revert;
}
.terms li > ul > li,
.terms li > ol > li,
.terms li > dl > li {
  margin-bottom: 1rem;
}
.terms li p + ul,
.terms li p + ol,
.terms li p + dl {
  margin-top: 1rem;
}
.terms li > h2 ~ p {
  margin-left: -1.125rem;
}
.terms .without-heading li::marker {
  text-transform: none;
  font-family: inherit;
}
.terms [data-section="privacy"] h2 {
  margin-top: 2rem;
}
.terms [data-section="privacy"] ul,
.terms [data-section="privacy"] ol,
.terms [data-section="privacy"] dl {
  margin: 1rem 0;
  padding: revert;
}
.terms [data-section="privacy"] ul:last-child,
.terms [data-section="privacy"] ol:last-child,
.terms [data-section="privacy"] dl:last-child {
  margin-bottom: 0;
}
.terms [data-section="privacy"] li {
  margin-bottom: 0.5rem;
}
.terms [data-section="merchant-terms"] h2 {
  text-transform: none;
  display: inline-block;
  font-size: 1rem;
}
.terms [data-section="merchant-terms"] h2.inline {
  margin-bottom: 0;
}
.terms [data-section="merchant-terms"] h2.inline + p {
  display: inline;
  margin-left: 0.5rem;
}
.terms [data-section="merchant-terms"] h2 ~ p {
  margin-left: 0;
}
.terms [data-section="merchant-terms"] h2 + ol {
  padding-left: 0;
}
.terms [data-section="merchant-terms"] h2 + ol li > ol,
.terms [data-section="merchant-terms"] h2 + ol li > ol > li > ol {
  margin-top: 1rem;
}
.terms [data-section="merchant-terms"] li {
  line-height: 1.5;
}
.terms [data-section="merchant-terms"] .without-heading > li {
  list-style-position: inside;
}
.terms [data-section="merchant-terms"] .without-heading > li::marker {
  font-size: 1rem;
  font-weight: 700;
}
.terms [data-section="merchant-terms"] .without-heading > li > ol {
  list-style-type: lower-alpha;
}
.terms [data-section="merchant-terms"] .without-heading > li > ol > li {
  text-indent: 2rem;
  margin-bottom: 1rem;
}
.terms [data-section="merchant-terms"] .without-heading > li > ol > li::marker {
  font-weight: 400;
}
.partner-rewards-terms h1 {
  font-size: 1.2rem;
}
.partner-rewards-terms h2:not(.inline) {
  display: block;
}
.partner-rewards-terms p:not(:first-of-type) {
  margin: 0.75rem;
}
.partner-rewards-terms ul > li {
  margin-bottom: 0;
}
.partner-rewards-terms .list-spacer {
  padding: 0 1rem;
}
.partner-rewards-terms .side-by-side {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.partner-rewards-terms table.terms-exhibit-table {
  border: 0;
  background-color: #000000;
  border-spacing: 1px;
  border-collapse: separate;
  min-width: 80%;
  margin: 0 auto;
}
.partner-rewards-terms table.terms-exhibit-table > tbody > tr > td {
  background-color: #ffffff;
  padding: 5px;
  text-align: left;
  vertical-align: center;
  width: 50%;
}
.testimonial {
  display: grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 6.25rem 1fr;
  grid-template-areas: "face identity" "quote quote";
  background-color: #ffffff;
  border-radius: 10px;
  grid-gap: 50px 80px;
  padding: 50px 80px;
  max-width: 680px;
  height: 423px;
  align-content: start;
}
@media only screen and (max-width: 960px) {
  .testimonial {
    margin: 0;
    margin-left: 20px;
    grid-gap: 10px 40px;
    padding: 35px 15px;
  }
  .testimonial:first-child {
    margin-left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .testimonial {
    gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-areas: "face" "identity" "quote";
  }
}
.testimonial__face {
  grid-area: face;
  justify-self: start;
  align-self: center;
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  background-color: #F2F3F4;
  background-size: cover;
  background-position: center center;
  mix-blend-mode: luminosity;
}
.testimonial__identity {
  grid-area: identity;
  justify-self: end;
  align-self: center;
  text-align: right;
}
@media only screen and (max-width: 480px) {
  .testimonial__identity {
    text-align: left;
    justify-self: start;
  }
}
.testimonial__identity p {
  font-size: 1.25rem;
}
.testimonial__quote {
  grid-area: quote;
  justify-self: stretch;
  align-self: start;
  text-align: justify;
}
@media only screen and (max-width: 960px) {
  .testimonial__quote {
    text-align: left;
  }
}
.testimonial__quote p {
  font-size: 1.125rem;
}
.text-block {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  max-width: clamp(23rem, (31vw + 1rem), 27rem);
  height: auto;
  margin-left: 7rem;
}
@media only screen and (max-width: 960px) {
  .text-block {
    max-width: 100%;
    order: 1;
    margin: 0;
  }
}
.text-block.--fullWidth {
  max-width: 100%;
  margin: 0;
}
.text-block.--alternate {
  margin: 0 3rem 0 0;
  max-width: 470px;
  max-width: clamp(20rem, (25vw + 1rem), 30rem);
}
@media only screen and (max-width: 960px) {
  .text-block.--alternate {
    max-width: 100%;
  }
}
.text-block h4 {
  margin-bottom: 1rem;
}
.thank-you img {
  max-width: 5rem;
  margin-bottom: 1.5rem;
}
.thank-you h1 {
  font-size: 2rem;
}
.thank-you .button {
  margin-top: 1.5rem;
}
/*
	Fonts
*/
/* 
	Colors
*/
/* 
	Buttons
*/
/*
	Header
*/
/* 
	Box shadow
*/
/* 
	Border radius
*/
/*
	Logos
*/
/* 
	Media Queries
	usage: @media @1280-down { ... };
*/
html,
body {
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
  margin: 0;
  color: #021431;
}
h1,
h2,
h3,
h4,
h5,
h6,
lh {
  font-weight: 600;
  line-height: 1.2;
}
a {
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: #00A7FF;
  font-weight: 700;
}
a:hover {
  color: #00A7FF;
  text-decoration: underline;
}
a.alternate-link {
  color: #021431;
}
a.alternate-link:hover {
  color: #021431;
}
a.no-decoration {
  color: transparent;
  background-color: transparent;
  display: block;
}
a.no-decoration:hover {
  color: transparent;
  background-color: transparent;
  text-decoration: none;
}
span,
small {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
}
strong {
  color: inherit;
  font-size: inherit;
  font-weight: 700;
}
h1 {
  font-size: 2.5rem;
  line-height: 1.25;
}
h1:not(:only-child) {
  margin-bottom: 1rem;
}
h2 {
  min-height: 0;
  font-size: 2.25rem;
  font-size: clamp(1.5rem, 1.0500rem + 2.0000vw, 2.25rem);
  line-height: 1.28;
}
h2:not(:only-child):not(h2[class*='__title']) {
  margin-bottom: 1rem;
}
h3 {
  min-height: 0;
  font-size: 1.75rem;
  font-size: clamp(1.25rem, 0.9500rem + 1.3333vw, 1.75rem);
  line-height: 1.2;
}
h3:not(:only-child) {
  margin-bottom: 1rem;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.2;
}
h5 {
  color: #00A7FF;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
h6,
p {
  font-size: 1.125rem;
  line-height: 1.5;
}
h6 {
  text-transform: uppercase;
}
h6:not(:last-child) {
  margin-bottom: 1rem;
}
p {
  font-weight: 400;
}
p:not(:first-of-type) {
  margin-top: 1rem;
}
p[class*='__paragraph'] {
  font-size: 1.375rem;
}
small {
  display: inline-block;
  font-size: 0.875rem;
}
html {
  overflow-x: hidden;
  height: -webkit-fill-available;
}
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
body * {
  box-sizing: border-box;
}
body.--withModal {
  position: fixed;
}
img {
  width: 100%;
  height: auto;
  display: inline-block;
}
picture {
  display: block;
}
[data-section="about-hiring"] picture {
  max-width: 1000px;
  margin: auto;
}
.main {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.inner {
  width: 100%;
  max-width: 1220px;
  padding: 4rem 2rem;
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .inner {
    max-width: 640px;
    padding: 2rem;
  }
}
.inner.--small {
  padding: 1rem 2rem;
}
.inner.--center {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: clamp(640px, 41vw, 41%);
}
.inner.--textBlock {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  max-width: 810px;
  padding: 5rem 0;
}
@media only screen and (max-width: 960px) {
  .inner.--textBlock {
    max-width: 640px;
    padding: 2rem;
  }
}
@media only screen and (max-width: 640px) {
  [data-section="cta-boxes"] .inner {
    padding: 0;
  }
}
.fixed-down-arrow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 90;
  bottom: 0;
}
.fixed-down-arrow__icon {
  font-size: 2rem;
  color: #00A7FF;
  cursor: pointer;
  height: 3rem;
  animation: 2s ease-in-out bounce infinite;
}
.fixed-down-arrow__icon:hover,
.fixed-down-arrow__icon:focus {
  color: #0293EC;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
[class*="-modal"] {
  display: none;
}
/* For screen readers */
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
ul,
ol {
  margin: 0;
  padding: 0;
  /*
	&.--counter {
		ol {
			counter-reset: item;
		}
		li:before {
			content: counters(item, ".") " ";
			counter-increment: item;
		}
	}*/
}
ul.--type-alpha > li,
ol.--type-alpha > li {
  list-style-type: lower-alpha !important;
}
ul.--type-alpha-upper > li,
ol.--type-alpha-upper > li {
  list-style-type: upper-alpha !important;
}
ul.--type-num > li,
ol.--type-num > li {
  list-style-type: decimal !important;
}
ul.--type-roman > li,
ol.--type-roman > li {
  list-style-type: upper-roman !important;
}
ul.--type-none > li,
ol.--type-none > li {
  list-style-type: none !important;
}
/*
ol.--with-counter {
	counter-reset: item;
	li {
		display: block;
		&:before {
			content: counters(item, ".") " ";
			counter-increment: item;
		}
	}
}*/
li {
  list-style-type: none;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-bottom: 0.5rem;
}
li:last-child {
  margin-bottom: 0;
}
.normal-list {
  padding-left: 1.125rem;
}
.normal-list li {
  list-style-type: inherit;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.button-wrapper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  gap: 0.5rem;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-center-left {
  text-align: center;
}
@media only screen and (max-width: 640px) {
  .text-center-left {
    text-align: left;
  }
}
@media only screen and (max-width: 960px) {
  .text-center-left.--split {
    text-align: left;
    align-items: flex-start;
  }
}
/* Section spacing */
.mb-section-s {
  margin-bottom: 60px;
}
@media only screen and (max-width: 960px) {
  .mb-section-s {
    margin-bottom: 30px;
  }
}
.mb-section-m {
  margin-bottom: 120px;
}
@media only screen and (max-width: 960px) {
  .mb-section-m {
    margin-bottom: 60px;
  }
}
.mb-section-l {
  margin-bottom: 160px;
}
@media only screen and (max-width: 960px) {
  .mb-section-l {
    margin-bottom: 60px;
  }
}
.mt-m {
  margin-top: 30px;
}
.mb-s {
  margin-bottom: 10px;
}
.mb-m {
  margin-bottom: 20px;
}
.mb-l {
  margin-bottom: 50px;
}
.ml-m {
  margin-left: 2rem;
}
.pl-s {
  padding-left: 1rem;
}
.text-indent {
  text-indent: 4rem;
}
.spacer-top {
  margin-top: 2rem;
}
.spacer-top-xs {
  margin-top: 0.5rem;
}
.spacer-bottom {
  margin-bottom: 2rem;
}
.spacer-bottom-s {
  margin-bottom: 1rem;
}
.spacer-bottom-xs {
  margin-bottom: 0.5rem;
}
.page-break-before {
  margin-top: 250px;
}
@media print {
  .page-break-before {
    margin-top: 0;
    page-break-before: always;
  }
}
.standard-width {
  width: 100%;
  max-width: 1220px;
  padding: 0 2rem;
}
.standard-width.--large {
  max-width: 1980px;
}
.standard-width.--medium {
  max-width: 1000px;
}
@media only screen and (max-width: 960px) {
  .standard-width {
    padding: 0 1rem;
  }
}
.divider {
  background-color: #E5E7EA;
  width: 100%;
  height: 1px;
  margin: 0;
  border: 0;
}
.full-height {
  min-height: 100vh;
}
.full-width {
  width: 100%;
}
.inline {
  display: inline;
}
.policy-table {
  border-collapse: collapse;
  border: 1px solid #bfbfbf;
  margin: 2rem 0;
}
.policy-table th {
  border: 1px solid #bfbfbf;
}
.policy-table td {
  border: 1px solid #bfbfbf;
  padding: 5px;
  vertical-align: top;
}
.policy-table td p {
  margin-top: 0;
}
.policy-table thead {
  background-color: #bdd6ee;
}
.policy-contact-info {
  padding-left: 1.875rem;
  overflow: auto;
}
.policy-contact-info p {
  margin-top: 0;
}
.policy-contact-info p:first-child {
  margin-top: 0.5rem;
}
.no-login .header__login,
.no-login .hamburger__menu .bottom {
  display: none;
}
.value-prop {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  text-align: center;
}
@media only screen and (min-width: 641px) and (max-width: 1280px) {
  .value-prop {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 2.5rem;
  }
}
.value-prop__item {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  border: 3px solid #7FD3FF;
  border-radius: 1rem;
  overflow: hidden;
}
@media (hover: hover) and (pointer: fine) {
  .value-prop__item:hover {
    box-shadow: -2px 5px 15px rgba(0, 0, 0, 0.15);
    transform: scale(1.03);
  }
  .value-prop__item:hover .value-prop__image {
    background-image: url('https://assets.prizeout.com/partners/value-props/Monetize-hover.png');
  }
  .value-prop__item:hover:nth-child(2) .value-prop__image {
    background-image: url('https://assets.prizeout.com/partners/value-props/Retention-hover.png');
  }
  .value-prop__item:hover:nth-child(3) .value-prop__image {
    background-image: url('https://assets.prizeout.com/partners/value-props/Purchase-hover.png');
  }
  .value-prop__item:hover:nth-child(4) .value-prop__image {
    background-image: url('https://assets.prizeout.com/partners/value-props/Instant-hover.png');
  }
  .value-prop__item:hover .value-prop__details p {
    display: block;
  }
}
@media only screen and (max-device-width: 768px) {
  .value-prop__item .value-prop__details p {
    display: block;
    padding: 0 1rem;
  }
}
.value-prop__image {
  background-image: url('https://assets.prizeout.com/partners/value-props/Monetize-default.png');
  width: 100%;
  height: 330px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.value-prop__item:nth-child(2) .value-prop__image {
  background-image: url('https://assets.prizeout.com/partners/value-props/Retention-default.png');
}
.value-prop__item:nth-child(3) .value-prop__image {
  background-image: url('https://assets.prizeout.com/partners/value-props/Purchase-default.png');
}
.value-prop__item:nth-child(4) .value-prop__image {
  background-image: url('https://assets.prizeout.com/partners/value-props/Instant-default.png');
}
.value-prop__content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0.75rem;
}
.value-prop__plus {
  max-width: 24px;
  align-self: flex-end;
}
.value-prop__details {
  user-select: none;
}
.value-prop__details p {
  color: #99A0AC;
  margin-top: 0.5rem;
  line-height: 1.2;
  display: none;
}
.video-player {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 20px;
}
.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.perks-hero {
  display: grid;
  grid-template-columns: 1fr 40%;
  grid-template-areas: "intro img" "items img";
  gap: 6rem 2rem;
}
@media only screen and (max-width: 960px) {
  .perks-hero {
    grid-template-columns: 1fr;
    grid-template-areas: "intro" "items" "img";
    gap: 3rem;
  }
}
.perks-hero__intro {
  grid-area: intro;
  justify-self: stretch;
  align-self: start;
}
.perks-hero__items {
  grid-area: items;
  justify-self: stretch;
  align-self: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4vw 2vw;
}
@media only screen and (max-width: 960px) {
  .perks-hero__items {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
.perks-hero__items__item {
  justify-self: stretch;
  align-self: stretch;
  border-left: 4px solid #7FD3FF;
  padding-left: 8px;
}
.perks-hero__items__item > span {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
  display: block;
}
.perks-hero__img {
  grid-area: img;
  justify-self: end;
  align-self: center;
}
@media only screen and (max-width: 960px) {
  .perks-hero__img {
    justify-self: center;
    margin-bottom: 30px;
  }
}
.perks-hero__img > img {
  width: 100%;
}
.perks-brands h4 {
  margin-bottom: 2rem;
  font-size: 36px;
  text-align: left;
  font-weight: 600;
}
@media only screen and (max-width: 960px) {
  .perks-brands h4 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.perks-brands__logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  gap: 1rem;
  max-width: 70%;
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .perks-brands__logos {
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .perks-brands__logos {
    grid-template-columns: 1fr;
  }
}
.perks-brands__logo {
  display: block;
  max-width: 9.375rem;
}
@media only screen and (max-width: 960px) {
  .perks-brands__logo + p {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .perks-brands__logo + p {
    display: block;
  }
}
.perks-signup {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "title img" "button img";
  gap: 0 2rem;
}
@media only screen and (max-width: 960px) {
  .perks-signup {
    grid-template-columns: auto;
    grid-template-areas: "title" "img" "button";
  }
}
.perks-signup__title {
  grid-area: title;
  justify-self: start;
  align-self: start;
  text-align: left;
}
@media only screen and (max-width: 960px) {
  .perks-signup__title {
    text-align: center;
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .perks-signup__title {
    margin-bottom: 2rem;
  }
}
.perks-signup__button {
  grid-area: button;
  justify-self: stretch;
  align-self: stretch;
  text-align: left;
}
.perks-signup__img {
  grid-area: img;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
}
.perks-signup__img > img {
  position: absolute;
  right: 0;
  top: -2vw;
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .perks-signup__img > img {
    position: unset;
  }
}
.perks-case-study {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto auto;
  grid-template-areas: "header header" "quote points";
}
@media only screen and (max-width: 960px) {
  .perks-case-study {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "header" "quote" "points";
  }
}
.perks-case-study__header {
  grid-area: header;
  justify-self: stretch;
  align-self: stretch;
  margin-bottom: 30px;
}
.perks-case-study__header__case-study {
  color: #00A7FF;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}
.perks-case-study__header__title {
  vertical-align: middle;
  margin-bottom: 30px;
  text-align: left;
}
.perks-case-study__header__title > img {
  vertical-align: middle;
  width: 75px;
  height: 45px;
}
@media only screen and (max-width: 960px) {
  .perks-case-study__header__title > img {
    width: 47px;
    height: 28px;
  }
}
.perks-case-study__header > p {
  max-width: 72%;
}
@media only screen and (max-width: 960px) {
  .perks-case-study__header > p {
    max-width: 100%;
  }
}
.perks-case-study__quote {
  grid-area: quote;
  justify-self: stretch;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 50px;
}
@media only screen and (max-width: 960px) {
  .perks-case-study__quote {
    padding: 20px 15px;
    margin-bottom: 10px;
  }
}
.perks-case-study__quote__quote {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 25px;
}
.perks-case-study__points {
  grid-area: points;
  justify-self: stretch;
  align-self: center;
}
.perks-case-study__points > ul {
  list-style: none;
  vertical-align: middle;
  padding-left: 40px;
}
@media only screen and (max-width: 960px) {
  .perks-case-study__points > ul {
    padding-left: 0;
  }
}
.perks-case-study__points > ul > li {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  vertical-align: middle;
  background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/perks/check.png');
  background-position: left center;
  background-repeat: no-repeat;
  line-height: 28px;
  margin: 20px 0;
  padding-left: 38px;
}
.facts-chart {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}
.facts-chart h1,
.facts-chart h2,
.facts-chart h3,
.facts-chart h4 {
  min-height: 0vw;
  text-transform: none;
  margin: 0;
}
.terms [data-section="privacy"] .facts-chart h1,
.terms [data-section="privacy"] .facts-chart h2,
.terms [data-section="privacy"] .facts-chart h3,
.terms [data-section="privacy"] .facts-chart h4 {
  margin: 0;
}
.facts-chart h1,
.facts-chart h3,
.facts-chart h4 {
  color: #FFFFFF;
}
.facts-chart h1 {
  font-size: 3rem;
  font-size: clamp(2.5rem, 2.5vw + 1rem, 3rem);
}
.facts-chart h2 {
  font-size: 2rem;
  font-size: clamp(1.5rem, 0.833vw + 1rem, 2rem);
}
.facts-chart h3 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 0.833vw + 1rem, 1.75rem);
}
.facts-chart h4 {
  font-size: 1.125rem;
}
@media only screen and (max-width: 480px) {
  .facts-chart h4 {
    font-size: 1rem;
  }
}
.facts-chart .normal-list li,
.facts-chart p {
  text-align: unset;
}
.facts-chart p:not(:first-of-type) {
  margin-top: 0;
}
.facts-chart__caption {
  justify-self: end;
}
.facts-chart__grid {
  display: grid;
  grid-template-columns: minmax(0, 23%) minmax(0, 1fr);
  grid-template-rows: auto;
  gap: 0;
  align-items: stretch;
  justify-items: stretch;
}
.facts-chart__grid--one-column {
  grid-template-columns: minmax(0, 1fr);
}
.facts-chart__grid--two-columns {
  grid-template-columns: minmax(0, 35%) auto;
}
.facts-chart__grid--two-columns .facts-chart__content {
  align-items: flex-start;
}
@media only screen and (max-width: 960px) {
  .facts-chart__grid {
    grid-template-columns: minmax(0, 1fr);
  }
}
.facts-chart__grid--three-columns {
  grid-template-columns: minmax(0, 40%) repeat(2, 30%);
}
.facts-chart__grid--three-columns .facts-chart__heading {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 960px) {
  .facts-chart__grid--three-columns {
    grid-template-columns: minmax(0, 50%) repeat(2, 25%);
  }
}
.facts-chart__grid > div {
  padding: 0.5rem 1.5rem;
}
@media only screen and (max-width: 480px) {
  .facts-chart__grid > div {
    padding: 0.5rem;
  }
}
.facts-chart__heading {
  background-color: #b9bdc0;
}
@media only screen and (max-width: 960px) {
  .facts-chart__heading + .facts-chart__content {
    border-top: none;
  }
}
.facts-chart__header .facts-chart__heading {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: #57585b;
}
@media only screen and (max-width: 960px) {
  .facts-chart__header .facts-chart__heading {
    justify-content: flex-start;
  }
}
.facts-chart__content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #b9bdc0;
  border-top: 1px solid #b9bdc0;
}
.facts-chart__content:first-child {
  border-left: 1px solid #b9bdc0;
}
@media only screen and (max-width: 960px) {
  .facts-chart__content {
    border-left: 1px solid #b9bdc0;
  }
}
.facts-chart__grid:last-child .facts-chart__content {
  border-bottom: 1px solid #b9bdc0;
}
.facts-chart__header .facts-chart__grid:last-child .facts-chart__content {
  border: none;
  border-bottom: 2px solid #57585b;
}
.landing-hero {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 100px;
}
@media only screen and (max-width: 960px) {
  .landing-hero {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 30px;
  }
}
.landing-hero__content {
  justify-self: stretch;
  align-self: center;
}
.landing-hero__image {
  justify-self: center;
  align-self: center;
}
.landing-hero__image > img {
  width: 100%;
  max-width: 370px;
}
.landing-faq {
  padding: 4rem 0;
}
.landing-faq h2 {
  color: #40BDFF;
  font-size: 1.25rem;
}
@media only screen and (max-width: 960px) {
  .landing-faq h2 {
    text-align: center;
  }
}
.landing-faq__item {
  border-bottom: 2px solid #00A7FF;
  padding: 1rem 0;
}
.landing-faq__question {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.landing-faq__question h3,
.landing-faq__question span {
  color: #00A7FF;
  font-size: 2rem;
}
.landing-faq__question span {
  margin-right: 1rem;
}
.landing-faq__answer {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  padding-right: 4rem;
  margin-bottom: 1rem;
}
.landing-testimonials h2 {
  text-align: center;
}
.landing-testimonials__container {
  overflow-x: auto;
  padding: 20px 0;
}
.landing-testimonials__boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .landing-testimonials__boxes {
    width: auto;
  }
}
.landing-testimonials__box {
  justify-self: center;
  align-self: center;
  border-radius: 15px;
  border: 2px solid #E5E7EA;
  transform: translate(0, 0);
  box-shadow: 10px 10px 0 #E5E7EA;
  padding: 20px;
  width: 340px;
  transition: all ease-out 0.3s;
}
@media only screen and (max-width: 960px) {
  .landing-testimonials__box {
    width: 80vw;
  }
}
.landing-testimonials__box:hover {
  transform: translate(-5px, -5px);
  box-shadow: 15px 15px 0 #E5E7EA;
}
.landing-testimonials__box__logo {
  margin-bottom: 18px;
}
.landing-testimonials__box__logo > img {
  height: 68px;
  width: auto;
}
.landing-testimonials__box__logo.--medium > img {
  height: 42px;
  width: auto;
}
.landing-testimonials__box > p {
  margin-bottom: 10px;
}
.landing-work-with-us h2 {
  text-align: left;
}
.landing-work-with-us > p {
  margin-bottom: 50px;
}
.landing-work-with-us__boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  gap: 30px;
}
@media only screen and (max-width: 960px) {
  .landing-work-with-us__boxes {
    display: block;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 1fr);
  }
}
.landing-work-with-us__box {
  border-radius: 15px;
  border: 1px solid #E5E7EA;
  display: grid;
  grid-template-rows: auto 1fr auto 196px;
  margin-bottom: 30px;
}
.landing-work-with-us__box > h4 {
  justify-self: start;
  align-self: start;
  padding: 20px 20px 10px 20px;
}
.landing-work-with-us__box > p {
  justify-self: start;
  align-self: start;
  margin-bottom: 10px;
  padding: 0 20px;
}
.landing-work-with-us__box > a:not(.button) {
  justify-self: start;
  align-self: start;
  padding: 20px;
}
.landing-work-with-us__box__button {
  padding: 20px;
  justify-self: stretch;
  align-self: end;
}
.landing-work-with-us__box__button .button {
  display: block;
  text-align: center;
}
.landing-work-with-us__box__img {
  justify-self: stretch;
  align-self: stretch;
  background-color: #021431;
  border-radius: 0 0 15px 15px;
  background-size: cover;
  border-top: 1px solid #E5E7EA;
}
.landing-work-with-us__box__img.--bg-api {
  background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/API-Image.png');
}
.landing-work-with-us__box__img.--bg-ui {
  background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/UI-Image.png');
}
.landing-work-with-us__box__img.--bg-bulk {
  background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/Bulk-Image.png');
}
.landing-get-started {
  padding: 60px 80px;
  background-color: #E4F5FF;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 50px;
  background-image: url('https://storage.googleapis.com/static.prizeout.com/website-redesign/images/landing/bg-cards.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
}
.landing-get-started h2 {
  text-align: left;
}
@media only screen and (max-width: 960px) {
  .landing-get-started {
    padding: 180px 16px 16px;
    background-position: center top;
  }
}
.landing-how-it-works {
  margin-bottom: 50px;
  /* Desktop */
  /* Mobile */
}
.landing-how-it-works__header {
  border-top: 4px solid #00A7FF;
  background-color: #F5F5F7;
  padding: 30px 0;
}
.landing-how-it-works__header p {
  max-width: 60%;
}
@media only screen and (max-width: 960px) {
  .landing-how-it-works__header p {
    max-width: 100%;
  }
}
.landing-how-it-works__desktop-content {
  display: grid;
  grid-template-columns: 60% 40%;
}
.landing-how-it-works__tabs {
  justify-self: start;
  align-self: center;
}
.landing-how-it-works__tab {
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;
}
.landing-how-it-works__tab:not(last-child) {
  margin-bottom: 2rem;
}
.landing-how-it-works__tab__num {
  background-color: #021431;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-out;
}
.landing-how-it-works__tab__text {
  justify-self: start;
  align-self: center;
  padding-left: 20px;
  transition: color 0.3s ease-out;
}
@media only screen and (max-width: 960px) {
  .landing-how-it-works__tab__text h4 {
    font-size: 1.1rem;
    transition: color 0.3s ease-out;
  }
  .landing-how-it-works__tab__text p {
    font-size: 1rem;
  }
}
.landing-how-it-works__tab.--selected .landing-how-it-works__tab__num {
  background-color: #00A7FF;
}
.landing-how-it-works__tab.--selected .landing-how-it-works__tab__text > h4 {
  color: #00A7FF;
}
.landing-how-it-works__tab-content {
  justify-self: end;
  align-self: center;
}
.landing-how-it-works__tab-content > img {
  transform: translateY(-70px);
  max-width: 260px;
}
.landing-how-it-works__mobile-image {
  padding: 40px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.landing-how-it-works__mobile-image > img {
  width: auto;
  max-height: 60vh;
}
.landing-brands__line {
  overflow-x: hidden;
  position: relative;
}
.landing-brands__line__brands {
  position: relative;
  width: 100%;
  height: 70px;
}
.landing-brands__line__fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, #ffffff 100%);
}
.landing-brands__brand {
  transition: left linear 0.5s, right linear 0.5s;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}
.landing-form iframe {
  width: 100%;
  max-width: 650px;
  border: none;
}
