.section {
	.flexbox();
	
	width: 100%;
	background-color: transparent;
	min-height: 100vh;
	
	&:first-child {
		margin-top: @header-height;
		min-height: ~'calc(100vh - @{header-height})';
	}
	
	.brands &,
	.about &,
	.localbrands &,
	.garyvee &,
	.partners &  {
		&:first-child {
			@media @960-down {
				min-height: initial;
			}
		}
	}

	&.--no-margin {
		margin-top: 0;
	}
	
	&.--pt-large {
		padding-top:	50px;
		
		@media @960-down {
			padding-top: 0;
		}
	}
	
	&.--adaptive {
		min-height: initial;
	}
	
	&.--alternate {
		background-color: @grey-light-medium;
		
		.grid__item[text-block] {
			grid-row-start: 1;
			
			@media @960-down {
				grid-row-start: 2;
			}
		}
		
		.text-block {
			margin-right: 7rem;
			margin-left: 0;
		}
	}
	
	&.--altBackground {
		background-color: @grey-light-medium;
		
		@media @960-down {
			.grid__item:first-child {
				order: 2;
			}
		}
	}
	
	&.--gradient-box-bottom {
		background: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(228,245,255,1) 50%);
		@media @960-down {
			background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(228,245,255,1) 30%);
		}
	}
	
	&.--dark {
		background-color: @blue-dark;
	}
	
	&.--light {
		background-color: @blue-light-background;
	}
	
	&.--column {
		.flexbox(@flexFlow: column; @alignItems: stretch;)
	}
	
	&__header {
		.flexbox(@flexFlow: column;);
		
		&.--center {
			text-align:	center;
		}
		
		&:not(:only-child) {
			margin-bottom: 3rem;
		
			@media @480-down {
				margin-bottom: 2rem;
			}
		}
	}
}
