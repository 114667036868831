.box {
	@box-padding: .75rem;
	@button-height: 2.75rem;
	@button-height-small: 2rem;
	
	.flexbox(@flexFlow: column; @alignItems: stretch);
	
	border-radius: @border-radius-tertiary;
	background-color: @white;
	box-shadow: @box-shadow-primary;
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
	
	&.--withButton {
		padding-bottom: ~'calc(@{box-padding} + @{button-height})';
		
		@media @1280-down {
			padding-bottom: ~'calc(@{box-padding} + @{button-height-small})';
		}
	}
	
	&.--fullWidth {
		padding: 0;
	}
	
	&.--solid {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between;);
		
		padding: 3.2rem;
		border-radius: @border-radius-primary;
		height: 100%;
		
		@media @640-down {
			padding: 5rem 2rem;
			border-radius: 0;
			justify-content: center;
		}
		
		* {
			color: @white;
		}
	}
	
	&.--dark {
		background-color: @blue-dark;
	}
	
	&.--medium {
		background-color: @blue-medium;
	}
	
	&__inner {
		.flexbox();
		
		position: relative;
		padding: @box-padding;
		height: auto;
		
		&.--medium {
			padding: 2rem 1rem;
		}
	}
	
	&__header {
		.flexbox();
		
		width: 100%;
		background-color: @grey-light-medium;
		padding: @box-padding;
	}
	
	&__logo {
		.--solid & {
			width: auto;
			height: 35px;
			margin-bottom: 1rem;
		}
	}
	
	&__copy {
		.flexbox(@flexFlow: column);
		
		padding: 1rem;
		width: 100%;
		
		p + h3 {
			margin-bottom: 0;
		}
	}
	
	&__button-wrapper {
		width: 100%;
		
		.--solid & {
			margin-top: 5rem;
			
			@media @640-down {
				margin-top: 2rem;
			}
			
			.button {
				width: auto;
				
				@media @640-down {
					width: 100%;
				}
			}
		}
		
		.button {
			width: 100%;
		}
	}
	
	&__list {
		width: 100%;
	}
	
	&__item {
		.flexbox(@justifyContent: flex-start);
	}
	
	&__icon-wrapper {
		.flexbox();
		
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-right: .5rem;
		
		&.--mediumBlue {
			background-color: @blue-medium;
		}
	}
	
	&__icon {
		color: @white;
	}
}
