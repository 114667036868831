.modal {
	.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: flex-start);
	.position-center(@position: fixed);
	
	border-radius: @border-radius-tertiary;
	z-index: 1001;
	width: ~'min(85%, 750px)';
	overflow: hidden;
	text-align: center;
	background-color: transparent;
	height: auto;
	
	.fa-exclamation-circle {
		display: none;
	}
	
	.form {
		margin: 0;
	}
	
	&__header {
		.flexbox();
		
		padding: 1rem 1.5rem;
		position: relative;
		
		.brand-form & {
			background-color: @blue-medium;
		}
		
		.partner-form &,
		.gov-form & {
			background-color: @blue-dark;
		}
	}
	
	&__logo {
		height: 30px;
		width: auto;
	}
	
	&__body {
		.flexbox();
		
		background-color: @white;
		height: 100%;
		overflow-y: auto;
		
		@media @640-down {
			text-align: left;
		}
	
		> div {
			padding: 2.5rem;
			
			@media @480-down {
				padding: 1.5rem;
			}
		}
	}
	
	&__copy {
		margin-bottom: 2.5rem;
	}
	
	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
		
		&:hover {
			.modal__icon {
				color: darken(@white, 10%);
				
				&.--dark {
					color: #4D5A6D;
				}
			}
		}
	}
	
	&__icon {
		color: @white;
		font-size: 1.5rem;
		
		&.--dark {
			color: #99A1AC;
		}
	}
	
	.button {
		margin: 2rem auto 0;
		
		@media @640-down {
			width: 100%;
			margin: 1rem auto 0;
		}
	}
	
	&__iframe {
		width: 100%;
		height: 100%;
		max-height: 95vh;
		overflow: auto;
	}
}
