.full-image-stack {
	width: 100%;
	height: 100%;
	min-height: inherit;
	position: relative;
	overflow: hidden; 
	
	> div {
		position: absolute;
		bottom: 0;
		right: 0;
		height: auto;
		max-height: 100%;
		
		> img {
			.transition();
			
			width: 100%;
			
			[data-section="business-hero"] & {
				@media @960-down {
					width: 110%;
				}
				
				@media @768-down {
					width: 125%;
				}
				
				@media @640-down {
					width: 140%;
				}
				
				@media @480-down {
					width: 150%;
				}
			}
		}
	}
	
	[data-layer] {
		position: absolute;
	}
	
	[data-section="business-hero"] & {
		[data-layer="bottom"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-hero-bottom.png');
			transform: translate(0, 0);
		}
		
		[data-layer="middle"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-hero-middle.png');
			transform: translate(0, 12%);
		}
		
		[data-layer="top"] {
			background-image: url('https://static.prizeout.com/website-redesign/images/business-hero-top.png');
			transform: translate(0, 28%);
		}
	
		[data-layer="text"] {
			.position-center();
			
			@media @960-down {
				transform: none;
				position: static;
			}
			
			@media @960-down-short {
				padding-top: 0;
			}
		}
	}
	
	&__image {
		background-size: contain;
		background-position: bottom right;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-repeat: no-repeat;
		
		@media @960-down {
			background-size: cover;
		}
		
		@media @960-down-short {
			background-position: top right;	
		}
	}
}
