.video-player {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%; // 16:9 aspect ratio
	border-radius: @border-radius-tertiary;
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}
