.value-prop {
	@plus-width: 24px;
	@value-prop-width: 250px;
	@value-prop-max-width: 360px;
	@value-prop-height: 330px;
	
	display: grid;
	grid-template-columns: ~'repeat(auto-fit, minmax(@{value-prop-width}, 1fr))';
	gap: 1.5rem;
	text-align: center;
	
	@media only screen and (min-width: 641px) and (max-width: 1280px) {
		grid-template-columns: ~'repeat(auto-fit, minmax(@{value-prop-max-width}, 1fr))';
		gap: 2.5rem;
	}
	
	&__item {
		.transition();
		
		border: 3px solid @blue-light;
		border-radius: 1rem;
		overflow: hidden;
		
		// Only show hover state if available
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				box-shadow: @box-shadow-primary;
				transform: scale(1.03);
				
				.value-prop__image {
					background-image: url('https://assets.prizeout.com/partners/value-props/Monetize-hover.png');
				}
				
				&:nth-child(2) .value-prop__image {
					background-image: url('https://assets.prizeout.com/partners/value-props/Retention-hover.png');
				}
				
				&:nth-child(3) .value-prop__image {
					background-image: url('https://assets.prizeout.com/partners/value-props/Purchase-hover.png');
				}
				
				&:nth-child(4) .value-prop__image {
					background-image: url('https://assets.prizeout.com/partners/value-props/Instant-hover.png');
				}
				
				.value-prop__details p {
					display: block;
				}
			}
		}
		
		// Show full details in mobile device
		@media only screen and (max-device-width: 768px) {
			.value-prop__details p {
				display: block;
				padding: 0 1rem;
			}
		}
	}
	
	&__image {
		background-image: url('https://assets.prizeout.com/partners/value-props/Monetize-default.png');
		width: 100%;
		height: @value-prop-height;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		position: relative;
		
		.value-prop__item:nth-child(2) & {
			background-image: url('https://assets.prizeout.com/partners/value-props/Retention-default.png');
		}
		
		.value-prop__item:nth-child(3) & {
			background-image: url('https://assets.prizeout.com/partners/value-props/Purchase-default.png');
		}
		
		.value-prop__item:nth-child(4) & {
			background-image: url('https://assets.prizeout.com/partners/value-props/Instant-default.png');
		}
	}
	
	&__content {
		.flexbox(@flexFlow: column; @justifyContent: space-between);
		
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding: .75rem;
	}
	
	&__plus {
		max-width: @plus-width;
		align-self: flex-end;
	}
	
	&__details {
		user-select: none;
		
		p {
			color: @grey-dark;
			margin-top: .5rem;
			line-height: 1.2;
			display: none;
		}
	}
}
