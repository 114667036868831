.form {
	* {
		box-sizing: border-box;
		font-family: @font-family;
	}
	
	width: 100%;

	&__row {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start);

		margin-bottom: .75rem;
		text-align: left;
		
		@media @640-down {
			flex-flow: column;
			margin-bottom: 0;
		}
	}

	&__column {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: flex-start;);

		flex: 1;
		position: relative;
		
		&:not(:first-child) {
			margin-left: 1rem;
			
			@media @640-down {
				margin-left: 0;
			}
		}
		
		@media @640-down {
			width: 100%;
			flex: auto;
			margin-bottom: 1rem;
		}
	}

	&__label {
		.transition();
		
		color: @blue-dark;
		font-size: .75rem;
		padding-left: .5rem;
	}

	&__input-wrapper {
		.flexbox(@flexFlow: column-reverse; @justifyContent: flex-start; @alignItems: stretch);
		
		overflow: hidden;
		width: 100%;
		height: 3.75rem;
		position: relative;

		&.--hasIcon {
			position: relative;
		}
		
		&.--hasButton {
			overflow: initial;
		}
	}

	&__input {
		.flexbox();
		
		width: 100%;
		border: 1px solid @form-border-color;
		border-radius: @border-radius-secondary;
		padding: .5rem;
		height: 40px;
		line-height: 1;
		background-color: @white;
		font-size: 100%;
		font-weight: 400;
		color: @blue-dark;
		-webkit-appearance: none;

		&:focus {
			outline: none;
			border-color: @blue;
		}
		
		&.--error {
			border-color: @red;
		}

		.--hasIcon & {
			padding: .5rem 2rem .5rem .5rem;
		}
		
		.--hasButton & {
			padding-right: 10rem;
			
			@media @480-down {
				padding-right: .5rem;
			}
		}
		
		/*
			Floating Labels
		*/
		
		// Make label look like placeholder text
		&:placeholder-shown + .form__label {
			cursor: text;
			max-width: ~'calc(100% - .5rem)';
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transform-origin: left bottom;
			transform: translateY(1.8rem); // Vertically center label inside input
			font-size: 1rem;
		}
		
		// Hide placeholder by default, label is pretending to be the placeholder text
		&::-webkit-input-placeholder,
		&::placeholder {
			opacity: 0;
		}
		
		// Override Chrome autofill styles 
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus { 
			-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
			-webkit-text-fill-color: @blue-dark !important;
			
			// Make autofill text same as input font size
			&::first-line {
				font-size: 1rem;
				font-family: @font-family;
			}
		}
		
		// Input has gained focus, move label above input	
		&:focus + .form__label,
		&:not(:placeholder-shown) + .form__label,
		&:-webkit-autofill + .form__label {
			transform: translateY(-3px);
			cursor: default;
			font-size: .75rem;
			padding-left: .3rem;
		}
		
		// Label is above input - focused, inactive state
		&:focus {
			outline: 0;
			border-color: @blue;
			
			+ .form__label {
				color: @blue-dark;
			}
		}
		
		// Label is above input - focused, active state
		&:not(:placeholder-shown) {
			border-color: #B7BBC0;
			
			+ .form__label {
				color: @blue-dark;
			}
		}
	}
	
	&__textarea {
		width: 100%;
		border: 1px solid @form-border-color;
		border-radius: @border-radius-secondary;
		padding: .5rem;
		line-height: 1.5;
		background-color: @white;
		font-size: 100%;
		font-weight: 400;
		color: @blue-dark;
		resize: none;
	}

	&__icon-wrapper {
		.--hasIcon & {
			.flexbox();

			position: absolute;
			height: 100%;
			top: 50%;
			transform: translateY(-50%);
			right: .5rem;
			left: unset;
		}
	}

	&__icon {
		color: @blue;
		font-size: 1rem;
		align-self: flex-end;

		.--hasIcon & {
			.transition(@prop: color);

			align-self: center;
			line-height: normal;
		}

		&.--eye {
			color: @grey-dark;
		}

		&.--success {
			color: @green;
		}
	}

	&__caption {
		margin-top: .25rem;
	}

	&__label,
	&__icon {
		&.--error {
			color: @red;
		}
	}
	
	&__select {
		border: 1px solid @form-border-color;
		border-radius: @border-radius-secondary;
		box-shadow: none;
		outline: none;
		width: 100%;
		height: 40px;
		padding: 0.5rem 1.75rem 0.5rem 0.5rem;
		font-size: 100%;
		appearance: none; // Hide default down arrow
		cursor: pointer;
		background-color: @white;
	}
	
	/* 
		Prevent input zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max 
	*/
	@media screen and (device-aspect-ratio: 9/16) {
		select,
		textarea,
		input[type="text"],
		input[type="password"],
		input[type="datetime"],
		input[type="datetime-local"],
		input[type="date"],
		input[type="month"],input[type="time"],
		input[type="week"],
		input[type="number"],
		input[type="email"],
		input[type="tel"],
		input[type="url"]{ 
			font-size: 16px;
		}
	}
}
