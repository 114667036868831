.facts-chart {
    @facts-light-grey: #b9bdc0;
    @facts-dark-grey: #57585b;
    
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    
    h1,
    h2,
    h3,
    h4 {
        min-height: 0vw; // safari bug fix for clamp
        text-transform: none;
        margin: 0;
    
        .terms [data-section="privacy"] & {
            margin: 0;
        }
    }
    
    h1,
    h3,
    h4 {
        color: @white;
    }
    
    h1 {
        font-size: 3rem;
        font-size: ~'clamp(2.5rem, 2.5vw + 1rem, 3rem)'; // 40px / 48px
    }
    
    h2 {
        font-size: 2rem;
        font-size: ~'clamp(1.5rem, 0.833vw + 1rem, 2rem)'; // 24px / 32px
    }
    
    h3 {
        font-size: 1.75rem;
        font-size: ~'clamp(1.5rem, 0.833vw + 1rem, 1.75rem)'; // 24px / 28px
    }
    
    h4 {
        font-size: 1.125rem;
        
        @media @480-down {
            font-size: 1rem;
        }
    }
    
    .normal-list li,
    p {
        text-align: unset;
    }
    
    p {
        &:not(:first-of-type) {
            margin-top: 0;
        }
    }
    
    &__caption {
        justify-self: end;
    }
    
    &__grid {
        display: grid;
        grid-template-columns: minmax(0, 23%) minmax(0, 1fr);
        grid-template-rows: auto;
        gap: 0;
        align-items: stretch;
        justify-items: stretch;
        
        &--one-column {
            grid-template-columns: minmax(0, 1fr);
        }
        
        &--two-columns {
            grid-template-columns: minmax(0, 35%) auto;
            
            .facts-chart__content {
                align-items: flex-start;
            }
        }
        
        @media @960-down {
            grid-template-columns: minmax(0, 1fr);
        }
        
        &--three-columns {
            grid-template-columns: minmax(0, 40%) repeat(2, 30%);
            
            .facts-chart__heading {
                .flexbox();
            }
            
            @media @960-down {
                grid-template-columns: minmax(0, 50%) repeat(2, 25%);
            }
        }
        
        > div {
            padding: 0.5rem 1.5rem;
            
            @media @480-down {
                padding: .5rem;
            }
        }
    }
    
    &__heading {
        background-color: @facts-light-grey;
        
        + .facts-chart__content {
            @media @960-down {
                border-top: none;
            } 
        }
        
        .facts-chart__header & {
            .flexbox();
            
            background-color: @facts-dark-grey;
            
            @media @960-down {
                justify-content: flex-start;
            }
        }
    }
    
    &__content {
        .flexbox(@justifyContent: flex-start);
        
        border-right: 1px solid @facts-light-grey;
        border-top: 1px solid @facts-light-grey;
        
        &:first-child {
            border-left: 1px solid @facts-light-grey;
        }
        
        @media @960-down {
            border-left: 1px solid @facts-light-grey;
        }
        
        .facts-chart__grid:last-child & {
            border-bottom: 1px solid @facts-light-grey;
            
            .facts-chart__header & {
                border: none;
                border-bottom: 2px solid @facts-dark-grey;
            }
        }
    }
}
