.platform-logos {
	@platform-logo-width: 125px;
	
	display: grid;
	grid-template-columns: ~'repeat(auto-fit, minmax(@{platform-logo-width}, 1fr))';
	gap: 1rem;
	
	&__logo {
		max-width: @platform-logo-width;
	}
}
